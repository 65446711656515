import React, { useState } from "react";
import { Col, Modal, Row } from "react-bootstrap";
import config from "../config/config";
import { toast } from "react-toastify";
import { isEmpty } from "../config/common";

const AddStudentWrokModal = ({ show, handleClose }) => {
  const [formData, setFormData] = useState({});
  const [Errors, setError] = useState({});

  const onChangeFunc = (e) => {
    const { id, files, value } = e.target;
    setError({});
    setFormData({ ...formData, [id]: files ? files[0] : value });
  };

  console.log("imgggg", formData?.img);

  const validation = () => {
    const err = {};
    if (!formData?.studentID) err.studentID = 'Please enter studentID';
    // if (isEmpty(formData?.img)) err.img = 'Please enter Image';
    if (!formData?.title) err.title = 'Please enter title';
    if (!formData?.aboutProject)
      err.aboutProject = 'Please enter about Project';
    if (!formData?.tools) err.tools = 'Please enter tools';
    if (!formData?.tags) err.tags = 'Please enter tags';
    return err;
  };

  const onSubmitFunc = async()=>{
    const valid = validation();
    if(!isEmpty(valid)) return setError(valid)
    toast?.success("Post uploaded successfully");
    handleClose();
  }


  return (
    <>
      <Modal
        centered
        size="xl"
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title className="cmn__modalTitle">
            Add Student Work
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col lg={7} className="mb-5 mb-lg-0">
              <div className="addwork__imageHolder d-flex flex-column justify-content-center align-items-center gap-3 position-relative">
                {formData?.img ? (
                  <img
                    alt=""
                    src={
                      typeof formData?.img == "string"
                        ? `${config?.image_Url}/posts/${formData?.img}`
                        : URL.createObjectURL(formData?.img)
                    }
                    // onChange={onChangeFunc}
                    className="d-flex img-fluid"
                  />
                ) : (
                  <>
                    <button className="addons__imgUploaderBtn">
                      <i class="fa-solid fa-plus"></i>
                    </button>
                    <p className="m-0 addons__imgUploaderHint text-center">
                      Add Image / Video Here
                    </p>
                  </>
                )}
                <input
                  type="file"
                  id="img"
                  // src={
                  //   typeof formData?.img == "string"
                  //     ? `${config?.image_Url}/posts/${formData?.img}`
                  //     : URL.createObjectURL(formData?.img)
                  // }
                  onChange={onChangeFunc}
                  className="addons__bannerAdderFileInp"
                />
              </div>
              <p className='m-0 cmn__errorHint'>{Errors?.img}</p>

            </Col>
            <Col lg={5}>
              <div className="mb-4">
                <div className="cmn__inputHolder py-1 px-2 position-relative">
                  <input
                    type="text"
                    placeholder="Enter the Student IMD No"
                    className="signIn__inputter"
                    value={formData?.studentID}
                    id="studentID"
                    onChange={onChangeFunc}
                  />
                </div>
                <p className='m-0 cmn__errorHint'>{Errors?.studentID}</p>
              </div>

              <div className="mb-4">
                <div className="cmn__inputHolder py-1 px-2 position-relative">
                  <input
                    type="text"
                    placeholder="Enter Your Project Title"
                    className="signIn__inputter"
                    value={formData?.title}
                    id="title"
                    onChange={onChangeFunc}
                  />
                </div>
                <p className='m-0 cmn__errorHint'>{Errors?.title}</p>
              </div>

              <div className="mb-4">
                <textarea
                  rows="4"
                  className="editProfile__textarea w-100 "
                  placeholder="Add some Details about your project"
                  value={formData?.aboutProject}
                  id="aboutProject"
                  onChange={onChangeFunc}
                />
                <p className='m-0 cmn__errorHint'>{Errors?.aboutProject}</p>
              </div>

              <div className="mb-4">
                <div className="cmn__inputHolder py-1 px-2 position-relative">
                  <input
                    type="text"
                    placeholder="Add Your Tools Used"
                    className="signIn__inputter"
                    value={formData?.tools}
                    id="tools"
                    onChange={onChangeFunc}
                  />
                </div>
                <p className='m-0 cmn__errorHint'>{Errors?.tools}</p>
              </div>

              <div className="mb-4">
                <label className="cmn__inputLabel mb-2">
                  Add some tags for people find easily
                </label>
                <div className="cmn__inputHolder py-1 px-2 position-relative">
                  <input
                    type="text"
                    placeholder="Add some tags for people find easily"
                    className="signIn__inputter"
                    value={formData?.tags}
                    id="tags"
                    onChange={onChangeFunc}
                  />
                </div>
                <p className='m-0 cmn__errorHint'>{Errors?.tags}</p>
              </div>
            </Col>

            <div className="d-flex justify-content-end align-items-center gap-4 mt-4">
              <button className="otpVerify__plainbutton" onClick={()=>handleClose()} >Cancel</button>
              <button className="primary__btn rounded-1" onClick={()=>onSubmitFunc()} >UPload</button>
            </div>
          </Row>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default AddStudentWrokModal;

import { combineReducers } from "redux"

const Initial_State = {
    userdata: {}
}


function redux(state = Initial_State, action) {

    switch (action.type) {

        case "userdata":
            return {
                ...state, ...action.data
            }
        default:
            return state
    }
}

const moveOn = combineReducers({ redux: redux })

export default moveOn;
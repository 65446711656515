// import React, { useEffect, useState } from "react";
// import Layout from "../Layout/Layout";
// import { Col, Dropdown, Row } from "react-bootstrap";
// import { allImages } from "../Images";
// import { useNavigate } from "react-router-dom";
// import { getBanners, saveBanners } from "../axios/admin.axios";
// import { toast } from "react-toastify";
// import { Swiper, SwiperSlide } from "swiper/react";
// import "swiper/css";
// import "swiper/css/pagination";
// import "swiper/css/navigation";
// import { Pagination, Navigation, Autoplay } from "swiper/modules";
// import config from "../config/config";
// import { isEmpty } from "../config/common";
// const AddBanner = () => {
//   const [activeSlide, setActiveSlide] = useState(0);
//   const navigate = useNavigate();

//   const initial = {
//     image: "",
//     desc: "",
//     selected: false,
//   };
//   const [slideArr, setSlideArr] = useState([]);

//   const onChangeFunc = (e, ind) => {
//     const { id, value, files } = e.target;
//     slideArr[ind][id] = files ? files[0] : value;
//     setSlideArr([...slideArr]);
//   };

//   const onForUploadSelect = (i) => {
//     slideArr[i].selected = !slideArr[i].selected;
//     setSlideArr([...slideArr]);
//   };

//   useEffect(() => {
//     getBann();
//   }, []);

//   const getBann = async () => {
//     const resp = await getBanners();
//     setSlideArr(resp?.data || slideArr);
//   };

//   const deleteSlide = (i) => {
//     var data = slideArr.filter((val, ii) => ii != i);
//     console.log("data", data);
//     setSlideArr([...data]);
//   };

//   const onSubmitFunc = async () => {

//     const getValid = slideArr.map((val)=>{
//       console.log("getValidddd", Object.values(val),val);
//     return ((val.image!=="") && !isEmpty(val.desc))
//     })
//     console.log("getValid",getValid);
//     if(getValid.includes(false)) return toast.error("Some datas are missing")

//     const formData = new FormData();

//     // slideArr.forEach((item, index) => {
//     //   formData.append(`images`, item.image);
//     // });
//     // formData.append(`data`, JSON.stringify(slideArr));
//     slideArr.forEach((item, index) => {
//       formData.append(`data[${index}][image]`, item.image);
//       formData.append(`data[${index}][desc]`, item.desc);
//       formData.append(`data[${index}][selected]`, item.selected);
//     });

//     formData.append("Length", slideArr.length);

//     const resp = await saveBanners(formData);
//     toast[resp?.success ?? "error"](resp?.msg ?? "Error");
//     window.location.reload();
//   };

//   console.log("sllideeeee", slideArr);

//   const onImageDelete =(i)=>{
//     slideArr[i].image = ''
//     setSlideArr([...slideArr])
//   }

//   return (
//     <>
//       <Layout>
//         <div className="dash__filtersHlder d-flex justify-content-end align-items-center gap-4 mb-3">
//           <Dropdown className="dash__filterDrop">
//             <Dropdown.Toggle id="dropdown-basic">Banners</Dropdown.Toggle>

//             <Dropdown.Menu>
//               <Dropdown.Item onClick={() => navigate("/addBanner")}>
//                 Banners
//               </Dropdown.Item>
//               <Dropdown.Item onClick={() => navigate("/addPlacedStudents")}>
//                 Placed Students
//               </Dropdown.Item>
//             </Dropdown.Menu>
//           </Dropdown>
//         </div>
//         <Row className="h-100">
//           <Col md={4}>
//             <div className="addons__slideHolder p-3 h-100">
//               <div className="addons__slideTop h-100">
//                 <h5 className="m-0 addons__leftTitle">Upload Banner</h5>

//                 <div className="addons__slideScroller">
//                   {slideArr.length != 0 &&
//                     slideArr.map((val, i) => {
//                       return (
//                         <>
//                           <div
//                             className={`addons__singleSlide p-2 mt-3 d-flex justify-content-between align-items-center ${
//                               val?.selected ? "active" : ""
//                             }`}
//                             onClick={() => onForUploadSelect(i)}
//                           >
//                             <p className="m-0 addons__slideLabel">
//                               Slide {i + 1}
//                             </p>
//                             <button className="addons__closer">
//                               <i class="fa-solid fa-xmark" />
//                             </button>
//                           </div>
//                         </>
//                       );
//                     })}
//                 </div>

//                 <div className="addons__bottomBtns mt-4 d-flex flex-column flex-lg-row gap-2 justify-content-center align-items-center">
//                   <button
//                     className="primary__btn whiteSpace__noWrapper rounded-1 flex-grow-1"
//                     onClick={() => setSlideArr([...slideArr, initial])}
//                   >
//                     Add Slides
//                   </button>
//                   <button
//                     className="primary__btn rounded-1 flex-grow-1"
//                     onClick={() => onSubmitFunc()}
//                   >
//                     Upload
//                   </button>
//                 </div>
//               </div>
//             </div>
//           </Col>

//           <Col md={8}>
//             <div className="addons__slidesPreview h-100">
//               <h5 className="m-0 addons__leftTitle">Preview</h5>

//               <div className="placedStds__swiperHolder mt-2">

//                   {slideArr.filter(val => val.selected).length != 0 ?
//                    <Swiper
//                    rewind={true}
//                    loop
//                    autoplay
//                    navigation={true}
//                    pagination={{
//                      dynamicBullets: true,
//                    }}
//                    modules={[Pagination, Autoplay, Navigation]}
//                    className="placedStds__swiper"
//                  >
//                   {slideArr.filter(val => val.selected).map((val) => {
//                       return (
//                         <SwiperSlide>
//                           <div className="addons__bannerPreviewer position-relative">
//                             <img

//                               src={
//                                 typeof val.image == "string"
//                                 ? `${config?.image_Url}/banners/${val.image}`
//                                 : URL.createObjectURL(val.image)
//                               }
//                               className="img-fluid addons__bannerPreview mt-3"
//                               alt=""
//                             />
//                             <p className="m-0 addons__bannerDesc text-center">
//                               {val?.desc}
//                             </p>
//                           </div>
//                         </SwiperSlide>
//                       );
//                     })}

//                 </Swiper>
//                 :
//                 <div className="" style={{height:"250px"}}></div>
//                     }
//               </div>

//               {slideArr.length != 0 &&
//                 slideArr.map((val, i) => {
//                   return (
//                     <div className="addons__bannerAdder mt-4 p-3">
//                       <h5 className="m-0 addons__leftTitle">Slide {i + 1}</h5>
//                       <div className="addons__bannerAdderFileInpHolder mt-3 position-relative d-flex justify-content-center align-items-center flex-column gap-3">
//                         {val.image && (
//                           <button className="addons__bannerEditor rounded-5" onClick={()=>onImageDelete(i)} >
//                             <i class="fa-solid fa-trash"></i> Delete
//                           </button>
//                         )}

//                         {val?.image ? (
//                           <img
//                             alt="PlacedStudent"
//                             style={{"min-height": "169px"}}
//                             className="img-fluid addons__bannerPreview mt-3"
//                             src={
//                               typeof val.image == "string"
//                                 ? `${config?.image_Url}/banners/${val.image}`
//                                 : URL.createObjectURL(val.image)
//                             }
//                           />
//                         ) : (
//                           <>
//                             <button className="addons__imgUploaderBtn">
//                               <i class="fa-solid fa-plus"></i>
//                             </button>
//                             <p className="m-0 addons__imgUploaderHint text-center">
//                               Add Image Here
//                             </p>
//                             <input
//                               type="file"
//                               id="image"
//                               className="addons__bannerAdderFileInp"
//                               onChange={(e) => onChangeFunc(e, i)}
//                             />
//                           </>
//                         )}
//                       </div>
//                       <input
//                         type="text"
//                         className="addons__bannerHintTyper p-2 mt-3"
//                         id="desc"
//                         value={val.desc}
//                         placeholder="Enter Text Here..."
//                         onChange={(e) => onChangeFunc(e, i)}
//                       />

//                       <div className="mt-3 text-end">
//                         <button className="primary__btn rounded-1" onClick={()=>onForUploadSelect(i)} >{val.selected ? "Unselect" :"Select"}</button>
//                       </div>
//                     </div>
//                   );
//                 })}
//             </div>
//           </Col>
//         </Row>
//       </Layout>
//     </>
//   );
// };

// export default AddBanner;

import React, { useEffect, useState } from 'react';
import Layout from '../Layout/Layout';
import { Col, Dropdown, Row } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { getBanners, saveBanners } from '../axios/admin.axios';
import { toast } from 'react-toastify';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import { Pagination, Navigation, Autoplay } from 'swiper/modules';
import config from '../config/config';
import { isEmpty } from '../config/common';
import DeleteSlideModal from '../Modals/DeleteSlideModal';

const AddBanner = () => {
  const [activeSlide, setActiveSlide] = useState(null);
  const navigate = useNavigate();

  const initial = {
    image: '',
    desc: '',
    selected: false,
  };
  const [slideArr, setSlideArr] = useState([]);

  const onChangeFunc = (e, ind) => {
    const { id, value, files } = e.target;
    slideArr[ind][id] = files ? files[0] : value;
    setSlideArr([...slideArr]);
  };

  const onForUploadSelect = (i) => {
    slideArr[i].selected = !slideArr[i].selected;
    setSlideArr([...slideArr]);
  };

  useEffect(() => {
    getBann();
  }, []);

  const getBann = async () => {
    const resp = await getBanners();
    setSlideArr(resp?.data || slideArr);
  };

  const deleteSlide = (i) => {
    var data = slideArr.filter((val, ii) => ii !== (i || activeSlide));
    console.log('data', data);
    setSlideArr([...data]);
    handleCloseDeleteSlide();
    setActiveSlide(null);
  };

  const onSubmitFunc = async () => {
    const getValid = slideArr.map((val) => {
      console.log('getValidddd', Object.values(val), val);
      return val.image !== '' && !isEmpty(val.desc);
    });
    console.log('getValid', getValid);
    if (getValid.includes(false)) return toast.error('Some data are missing');

    const formData = new FormData();
    slideArr.forEach((item, index) => {
      formData.append(`data[${index}][image]`, item.image);
      formData.append(`data[${index}][desc]`, item.desc);
      formData.append(`data[${index}][selected]`, item.selected);
    });

    formData.append('Length', slideArr.length);

    const resp = await saveBanners(formData);
    toast[resp?.success ?? 'error'](resp?.msg ?? 'Error');
    window.location.reload();
  };

  console.log('slideArr', slideArr);

  const onImageDelete = (i) => {
    slideArr[i].image = '';
    setSlideArr([...slideArr]);
  };

  const [showDeleteSlide, setShowDeleteSlide] = useState(false);
  const handleCloseDeleteSlide = () => setShowDeleteSlide(false);
  const handleShowDeleteSlide = () => setShowDeleteSlide(true);

  return (
    <>
      <Layout>
        <div className='dash__filtersHlder d-flex justify-content-end align-items-center gap-4 mb-3'>
          <Dropdown className='dash__filterDrop'>
            <Dropdown.Toggle id='dropdown-basic'>Banners</Dropdown.Toggle>

            <Dropdown.Menu>
              <Dropdown.Item onClick={() => navigate('/addBanner')}>
                Banners
              </Dropdown.Item>
              <Dropdown.Item onClick={() => navigate('/addPlacedStudents')}>
                Placed Students
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </div>
        <Row className='h-100'>
          <Col md={4}>
            <div className='addons__slideHolder p-3 h-100'>
              <div className='addons__slideTop h-100'>
                <h5 className='m-0 addons__leftTitle'>Upload Banner</h5>

                <div className='addons__slideScroller'>
                  {slideArr.length !== 0 &&
                    [...slideArr].reverse().map((val, i) => {
                      return (
                        <>
                          <div
                            className={`addons__singleSlide p-2 mt-3 d-flex justify-content-between align-items-center ${
                              val?.selected ? 'active' : ''
                            }`}
                            onClick={() =>
                              onForUploadSelect(slideArr.length - 1 - i)
                            }
                          >
                            <p className='m-0 addons__slideLabel'>
                              Slide {slideArr.length - i}
                            </p>
                            <button
                              className='addons__closer'
                              onClick={() => {
                                // deleteSlide(slideArr.length - 1 - i)
                                handleShowDeleteSlide();
                                setActiveSlide(slideArr.length - 1 - i);
                              }}
                            >
                              <i className='fa-solid fa-xmark' />
                            </button>
                          </div>
                        </>
                      );
                    })}
                </div>

                <div className='addons__bottomBtns mt-4 d-flex flex-column flex-lg-row gap-2 justify-content-center align-items-center'>
                  <button
                    className='primary__btn whiteSpace__noWrapper rounded-1 flex-grow-1'
                    onClick={() => setSlideArr([...slideArr, initial])}
                  >
                    Add Slides
                  </button>
                  <button
                    className='primary__btn rounded-1 flex-grow-1'
                    onClick={onSubmitFunc}
                  >
                    Upload
                  </button>
                </div>
              </div>
            </div>
          </Col>

          <Col md={8}>
            <div className='addons__slidesPreview h-100'>
              <h5 className='m-0 addons__leftTitle'>Preview</h5>

              <div className='placedStds__swiperHolder mt-2'>
                {slideArr.filter((val) => val.selected).length !== 0 ? (
                  <Swiper
                    rewind={true}
                    loop
                    autoplay
                    navigation={true}
                    pagination={{
                      dynamicBullets: true,
                    }}
                    modules={[Pagination, Autoplay, Navigation]}
                    className='placedStds__swiper'
                  >
                    {slideArr
                      .filter((val) => val.selected)
                      .map((val, i) => {
                        return (
                          <SwiperSlide key={i}>
                            <div className='addons__bannerPreviewer position-relative'>
                              <img
                                src={
                                  typeof val.image === 'string'
                                    ? `${config?.image_Url}/banners/${val.image}`
                                    : URL.createObjectURL(val.image)
                                }
                                className='img-fluid addons__bannerPreview mt-3'
                                alt=''
                              />
                              <p className='m-0 addons__bannerDesc text-center'>
                                {val?.desc}
                              </p>
                            </div>
                          </SwiperSlide>
                        );
                      })}
                  </Swiper>
                ) : (
                  <div className='' style={{ height: '250px' }}></div>
                )}
              </div>

              {slideArr.length !== 0 &&
                [...slideArr].reverse().map((val, i) => {
                  return (
                    <div className='addons__bannerAdder mt-4 p-3' key={i}>
                      <h5 className='m-0 addons__leftTitle'>
                        Slide {slideArr.length - i}
                      </h5>
                      <div className='addons__bannerAdderFileInpHolder mt-3 position-relative d-flex justify-content-center align-items-center flex-column gap-3'>
                        {val.image && (
                          <button
                            className='addons__bannerEditor rounded-5'
                            onClick={() => {
                              onImageDelete(slideArr.length - 1 - i);
                              // handleShowDeleteSlide();
                              // setActiveSlide(slideArr.length - 1 - i);
                            }}
                          >
                            <i className='fa-solid fa-trash'></i> Delete
                          </button>
                        )}

                        {val?.image ? (
                          <img
                            alt='PlacedStudent'
                            style={{ 'min-height': '169px' }}
                            className='img-fluid addons__bannerPreview mt-3'
                            src={
                              typeof val.image === 'string'
                                ? `${config?.image_Url}/banners/${val.image}`
                                : URL.createObjectURL(val.image)
                            }
                          />
                        ) : (
                          <>
                            <button className='addons__imgUploaderBtn'>
                              <i className='fa-solid fa-plus'></i>
                            </button>
                            <p className='m-0 addons__imgUploaderHint text-center'>
                              Add Image Here
                            </p>
                            <input
                              type='file'
                              id='image'
                              className='addons__bannerAdderFileInp'
                              onChange={(e) =>
                                onChangeFunc(e, slideArr.length - 1 - i)
                              }
                            />
                          </>
                        )}
                      </div>
                      <input
                        type='text'
                        className='addons__bannerHintTyper p-2 mt-3'
                        id='desc'
                        value={val.desc}
                        placeholder='Enter Text Here...'
                        onChange={(e) =>
                          onChangeFunc(e, slideArr.length - 1 - i)
                        }
                      />

                      <div className='mt-3 text-end'>
                        <button
                          className='primary__btn rounded-1'
                          onClick={() =>
                            onForUploadSelect(slideArr.length - 1 - i)
                          }
                        >
                          {val.selected ? 'Unselect' : 'Select'}
                        </button>
                      </div>
                    </div>
                  );
                })}
            </div>
          </Col>
        </Row>
      </Layout>

      {/* modals */}
      <DeleteSlideModal
        show={showDeleteSlide}
        handleClose={handleCloseDeleteSlide}
        deleteSlide={deleteSlide}
      />
      {/* end of modals */}
    </>
  );
};

export default AddBanner;

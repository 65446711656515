import React, { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { allImages } from "../Images";
import OtpInput from "react-otp-input";
import Countdown, { zeroPad } from "react-countdown";
import { useLocation, useNavigate } from "react-router-dom";
import { decryptData, obfuscateEmail, setLocalStorage } from "../config/common";
import { toast } from "react-toastify";
import { forgetPasswordmail, resendOTP } from "../axios/admin.axios";
import { useDispatch, useSelector } from "react-redux";

const OTPVerification = () => {
  const [otp, setOtp] = useState("");
  const navigate = useNavigate();
  const location = useLocation();
  const state = location.state;
  console.log("location", location);
  const [dummy, setDummy] = useState(false);
  const [verOtp, setVerOtp] = useState(decryptData(state?.OTP || 0));
  const [time, setTime] = useState(Date.now() + 30000);

  const userdata = useSelector(state => state?.redux?.userdata);
  const dispatch = useDispatch()

console.log("userdata",userdata);
  const renderer = ({ hours, minutes, seconds }) => (
    <span className="otpVerify__countdown">
      {zeroPad(minutes)}:{zeroPad(seconds)}
    </span>
  );

  const verifyOtp = async () => {
    if (otp === "") return toast.error("Enter OTP!!!");
    if (parseInt(otp) !== parseInt(verOtp))
      return toast.error("Please Enter valid OTP");
    setLocalStorage("token", state?.token);
    toast.success("Admin verified successfully");
    if(!state?.forgetPass){
      setDummy(!dummy)
      dispatch({
        type : "userdata" , 
        data : { userdata: state?.data }
    })
    }
    setTimeout(() => {
      navigate(state?.forgetPass ? "/resetPassword" : "/dashboard");
    }, 2000);
  };

  const onResendOtp = async () => {
    const resp = await resendOTP({ email: state?.email,forget : state?.forgetPass });
    if (resp?.status) {
      setVerOtp(decryptData(resp?.OTP));
      setTime(Date.now() + 30000);
      toast[resp?.success ?? "error"](resp?.msg ?? "Error");
    } else {
      toast.error("Error on resend");
    }
  };

  useEffect(() => {
    if (state?.forgetPass) {
      const getData = async () => {
        const resp = await forgetPasswordmail({});
        setVerOtp(decryptData(resp?.OTP));
      };
      getData();
    }
  }, [state]);

  return (
    <>
      <Container fluid className="p-0 signIn__commonBg cmn__bgAligner">
        <Row className="h-100 align-items-center">
          <Col
            md={6}
            className=" d-flex justify-content-center align-items-center"
          >
            <img
              src={allImages.logo}
              className="img-fluid signIn__logo"
              alt=""
            />
          </Col>
          <Col md={6} className="d-flex justify-content-center">
            <div className="p-3 p-sm-4 p-md-5 text-center signIn__formHolder">
              <img src={allImages.emailChecker} className="img-flui" alt="" />

              <h5 className="text-center singIn__formTitle">
                Check Your Mail !
              </h5>
              <p className="m-0 otpVerify__hint text-center">
                Enter The 4-Digit Code Sent to <br />
                <span>{obfuscateEmail(state?.email)}</span>
              </p>
              <div className="cmn__otpInpHolder d-flex justify-content-center mt-4">
                <OtpInput
                  value={otp}
                  onChange={setOtp}
                  numInputs={4}
                  renderSeparator={<span>-</span>}
                  renderInput={(props) => <input {...props} />}
                />
              </div>
              <div class="my-3 d-flex justify-content-between align-items-center">
                <button
                  disabled={time > Date.now()}
                  className="otpVerify__plainbutton otpVerify__only"
                  onClick={() => onResendOtp()}
                >
                  Resend OTP
                </button>
                {time ? (
                  <Countdown
                    onComplete={() => {
                      setTime(null);
                      setDummy(!dummy);
                    }}
                    date={time}
                    renderer={renderer}
                  />
                ) : (
                  <p className="m-0 otpVerify__hint text-center">0:00</p>
                )}
              </div>
              <button
                className="primary__btn rounded-1"
                onClick={() => verifyOtp()}
              >
                Verify OTP
              </button>
              <div class="my-3">
                <button  onClick={()=>navigate(-1)} className="otpVerify__plainbutton">Cancel</button>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default OTPVerification;

import { Dropdown } from 'react-bootstrap';
import { allImages } from './Images';

export const allData = {
  news: [
    {
      title: 'Vijay M Get Green Batch',
      time: '3hrs ago',
    },
    {
      title: 'Ananya G Placed in Sun Tv Network Chennai.',
      time: '3hrs ago',
    },
    {
      title: 'Karthik Kumar Got Yellow Batch',
      time: '3hrs ago',
    },
    {
      title: 'This week Winner is Susan K Graphic Design(Poster)',
      time: '3hrs ago',
    },
  ],
  placedStudent: [
    {
      name: 'Saravanan R',
      role: 'Sun Networks',
      img: allImages.studentOne,
    },
    {
      name: 'Saravanan R',
      role: 'Sun Networks',
      img: allImages.studentOne,
    },
    {
      name: 'Saravanan R',
      role: 'Sun Networks',
      img: allImages.studentOne,
    },
    {
      name: 'Saravanan R',
      role: 'Sun Networks',
      img: allImages.studentOne,
    },
  ],

  dashTableData: [
    {
      id: (
        <div className='d-flex justify-content-center align-items-center gap-2'>
          <button className='dash__newBatch rounded-1'>New</button>
          <p className='exchange_table_values m-0'>IMD-101-07-121</p>
        </div>
      ),
      name: (
        <p className='exchange_table_values m-0 text-center'>Anbalagan A</p>
      ),
      course: (
        <p className='exchange_table_values m-0 text-center'>UX/UI Design</p>
      ),
      location: (
        <p className='exchange_table_values m-0 exchange_table_address text-center'>
          Madurai
        </p>
      ),
      mobile: (
        <p className='exchange_table_values m-0 text-center'>+91 8012345663</p>
      ),
      gender: <p className='exchange_table_values m-0 text-center'>Male</p>,
      action: (
        <Dropdown className='dash__tableDrop'>
          <Dropdown.Toggle id='dropdown-basic'>
            <i class='fa-solid fa-ellipsis-vertical'></i>
          </Dropdown.Toggle>

          <Dropdown.Menu className='dash__tableDropMenu'>
            <Dropdown.Item className='dash__tableDropItem'>
              <i class='fa-solid fa-user me-2' /> View Profile
            </Dropdown.Item>
            <Dropdown.Item className='dash__tableDropItem'>
              <i class='fa-solid fa-pen me-2' />
              Edit Profile
            </Dropdown.Item>
            <Dropdown.Item className='dash__tableDropItem'>
              <i class='fa-solid fa-book me-2' />
              DESC Record
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      ),
    },
    {
      id: (
        <div className='d-flex justify-content-center align-items-center gap-2'>
          <button className='dash__newBatch rounded-1'>New</button>
          <p className='exchange_table_values m-0'>IMD-101-07-121</p>
        </div>
      ),
      name: (
        <p className='exchange_table_values m-0 text-center'>Anbalagan A</p>
      ),
      course: (
        <p className='exchange_table_values m-0 text-center'>UX/UI Design</p>
      ),
      location: (
        <p className='exchange_table_values m-0 exchange_table_address text-center'>
          Madurai
        </p>
      ),
      mobile: (
        <p className='exchange_table_values m-0 text-center'>+91 8012345663</p>
      ),
      gender: <p className='exchange_table_values m-0 text-center'>Male</p>,
      action: (
        <Dropdown className='dash__tableDrop'>
          <Dropdown.Toggle id='dropdown-basic'>
            <i class='fa-solid fa-ellipsis-vertical'></i>
          </Dropdown.Toggle>

          <Dropdown.Menu className='dash__tableDropMenu'>
            <Dropdown.Item className='dash__tableDropItem'>
              <i class='fa-solid fa-user me-2' /> View Profile
            </Dropdown.Item>
            <Dropdown.Item className='dash__tableDropItem'>
              <i class='fa-solid fa-pen me-2' />
              Edit Profile
            </Dropdown.Item>
            <Dropdown.Item className='dash__tableDropItem'>
              <i class='fa-solid fa-book me-2' />
              DESC Record
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      ),
    },
    {
      id: (
        <div className='d-flex justify-content-center align-items-center gap-2'>
          <button className='dash__newBatch rounded-1'>New</button>
          <p className='exchange_table_values m-0'>IMD-101-07-121</p>
        </div>
      ),
      name: (
        <p className='exchange_table_values m-0 text-center'>Anbalagan A</p>
      ),
      course: (
        <p className='exchange_table_values m-0 text-center'>UX/UI Design</p>
      ),
      location: (
        <p className='exchange_table_values m-0 exchange_table_address text-center'>
          Madurai
        </p>
      ),
      mobile: (
        <p className='exchange_table_values m-0 text-center'>+91 8012345663</p>
      ),
      gender: <p className='exchange_table_values m-0 text-center'>Male</p>,
      action: (
        <Dropdown className='dash__tableDrop'>
          <Dropdown.Toggle id='dropdown-basic'>
            <i class='fa-solid fa-ellipsis-vertical'></i>
          </Dropdown.Toggle>

          <Dropdown.Menu className='dash__tableDropMenu'>
            <Dropdown.Item className='dash__tableDropItem'>
              <i class='fa-solid fa-user me-2' /> View Profile
            </Dropdown.Item>
            <Dropdown.Item className='dash__tableDropItem'>
              <i class='fa-solid fa-pen me-2' />
              Edit Profile
            </Dropdown.Item>
            <Dropdown.Item className='dash__tableDropItem'>
              <i class='fa-solid fa-book me-2' />
              DESC Record
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      ),
    },
    {
      id: (
        <div className='d-flex justify-content-center align-items-center gap-2'>
          <button className='dash__newBatch rounded-1'>New</button>
          <p className='exchange_table_values m-0'>IMD-101-07-121</p>
        </div>
      ),
      name: (
        <p className='exchange_table_values m-0 text-center'>Anbalagan A</p>
      ),
      course: (
        <p className='exchange_table_values m-0 text-center'>UX/UI Design</p>
      ),
      location: (
        <p className='exchange_table_values m-0 exchange_table_address text-center'>
          Madurai
        </p>
      ),
      mobile: (
        <p className='exchange_table_values m-0 text-center'>+91 8012345663</p>
      ),
      gender: <p className='exchange_table_values m-0 text-center'>Male</p>,
      action: (
        <Dropdown className='dash__tableDrop'>
          <Dropdown.Toggle id='dropdown-basic'>
            <i class='fa-solid fa-ellipsis-vertical'></i>
          </Dropdown.Toggle>

          <Dropdown.Menu className='dash__tableDropMenu'>
            <Dropdown.Item className='dash__tableDropItem'>
              <i class='fa-solid fa-user me-2' /> View Profile
            </Dropdown.Item>
            <Dropdown.Item className='dash__tableDropItem'>
              <i class='fa-solid fa-pen me-2' />
              Edit Profile
            </Dropdown.Item>
            <Dropdown.Item className='dash__tableDropItem'>
              <i class='fa-solid fa-book me-2' />
              DESC Record
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      ),
    },
    {
      id: (
        <div className='d-flex justify-content-center align-items-center gap-2'>
          <button className='dash__newBatch rounded-1'>New</button>
          <p className='exchange_table_values m-0'>IMD-101-07-121</p>
        </div>
      ),
      name: (
        <p className='exchange_table_values m-0 text-center'>Anbalagan A</p>
      ),
      course: (
        <p className='exchange_table_values m-0 text-center'>UX/UI Design</p>
      ),
      location: (
        <p className='exchange_table_values m-0 exchange_table_address text-center'>
          Madurai
        </p>
      ),
      mobile: (
        <p className='exchange_table_values m-0 text-center'>+91 8012345663</p>
      ),
      gender: <p className='exchange_table_values m-0 text-center'>Male</p>,
      action: (
        <Dropdown className='dash__tableDrop'>
          <Dropdown.Toggle id='dropdown-basic'>
            <i class='fa-solid fa-ellipsis-vertical'></i>
          </Dropdown.Toggle>

          <Dropdown.Menu className='dash__tableDropMenu'>
            <Dropdown.Item className='dash__tableDropItem'>
              <i class='fa-solid fa-user me-2' /> View Profile
            </Dropdown.Item>
            <Dropdown.Item className='dash__tableDropItem'>
              <i class='fa-solid fa-pen me-2' />
              Edit Profile
            </Dropdown.Item>
            <Dropdown.Item className='dash__tableDropItem'>
              <i class='fa-solid fa-book me-2' />
              DESC Record
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      ),
    },
    // {
    //   id: (
    //     <div className='d-flex justify-content-center align-items-center gap-2'>
    //       <button className='dash__newBatch rounded-1'>New</button>
    //       <p className='exchange_table_values m-0'>IMD-101-07-121</p>
    //     </div>
    //   ),
    //   name: (
    //     <p className='exchange_table_values m-0 text-center'>Anbalagan A</p>
    //   ),
    //   course: (
    //     <p className='exchange_table_values m-0 text-center'>UX/UI Design</p>
    //   ),
    //   location: (
    //     <p className='exchange_table_values m-0 exchange_table_address text-center'>
    //       Madurai
    //     </p>
    //   ),
    //   mobile: (
    //     <p className='exchange_table_values m-0 text-center'>+91 8012345663</p>
    //   ),
    //   gender: <p className='exchange_table_values m-0 text-center'>Male</p>,
    //   action: (
    //     <Dropdown className='dash__tableDrop'>
    //       <Dropdown.Toggle id='dropdown-basic'>
    //         <i class='fa-solid fa-ellipsis-vertical'></i>
    //       </Dropdown.Toggle>

    //       <Dropdown.Menu className='dash__tableDropMenu'>
    //         <Dropdown.Item className='dash__tableDropItem'>
    //           <i class='fa-solid fa-user me-2' /> View Profile
    //         </Dropdown.Item>
    //         <Dropdown.Item className='dash__tableDropItem'>
    //           <i class='fa-solid fa-pen me-2' />
    //           Edit Profile
    //         </Dropdown.Item>
    //         <Dropdown.Item className='dash__tableDropItem'>
    //           <i class='fa-solid fa-book me-2' />
    //           DESC Record
    //         </Dropdown.Item>
    //       </Dropdown.Menu>
    //     </Dropdown>
    //   ),
    // },
    // {
    //   id: (
    //     <div className='d-flex justify-content-center align-items-center gap-2'>
    //       <button className='dash__newBatch rounded-1'>New</button>
    //       <p className='exchange_table_values m-0'>IMD-101-07-121</p>
    //     </div>
    //   ),
    //   name: (
    //     <p className='exchange_table_values m-0 text-center'>Anbalagan A</p>
    //   ),
    //   course: (
    //     <p className='exchange_table_values m-0 text-center'>UX/UI Design</p>
    //   ),
    //   location: (
    //     <p className='exchange_table_values m-0 exchange_table_address text-center'>
    //       Madurai
    //     </p>
    //   ),
    //   mobile: (
    //     <p className='exchange_table_values m-0 text-center'>+91 8012345663</p>
    //   ),
    //   gender: <p className='exchange_table_values m-0 text-center'>Male</p>,
    //   action: (
    //     <Dropdown className='dash__tableDrop'>
    //       <Dropdown.Toggle id='dropdown-basic'>
    //         <i class='fa-solid fa-ellipsis-vertical'></i>
    //       </Dropdown.Toggle>

    //       <Dropdown.Menu className='dash__tableDropMenu'>
    //         <Dropdown.Item className='dash__tableDropItem'>
    //           <i class='fa-solid fa-user me-2' /> View Profile
    //         </Dropdown.Item>
    //         <Dropdown.Item className='dash__tableDropItem'>
    //           <i class='fa-solid fa-pen me-2' />
    //           Edit Profile
    //         </Dropdown.Item>
    //         <Dropdown.Item className='dash__tableDropItem'>
    //           <i class='fa-solid fa-book me-2' />
    //           DESC Record
    //         </Dropdown.Item>
    //       </Dropdown.Menu>
    //     </Dropdown>
    //   ),
    // },
    // {
    //   id: (
    //     <div className='d-flex justify-content-center align-items-center gap-2'>
    //       <button className='dash__newBatch rounded-1'>New</button>
    //       <p className='exchange_table_values m-0'>IMD-101-07-121</p>
    //     </div>
    //   ),
    //   name: (
    //     <p className='exchange_table_values m-0 text-center'>Anbalagan A</p>
    //   ),
    //   course: (
    //     <p className='exchange_table_values m-0 text-center'>UX/UI Design</p>
    //   ),
    //   location: (
    //     <p className='exchange_table_values m-0 exchange_table_address text-center'>
    //       Madurai
    //     </p>
    //   ),
    //   mobile: (
    //     <p className='exchange_table_values m-0 text-center'>+91 8012345663</p>
    //   ),
    //   gender: <p className='exchange_table_values m-0 text-center'>Male</p>,
    //   action: (
    //     <Dropdown className='dash__tableDrop'>
    //       <Dropdown.Toggle id='dropdown-basic'>
    //         <i class='fa-solid fa-ellipsis-vertical'></i>
    //       </Dropdown.Toggle>

    //       <Dropdown.Menu className='dash__tableDropMenu'>
    //         <Dropdown.Item className='dash__tableDropItem'>
    //           <i class='fa-solid fa-user me-2' /> View Profile
    //         </Dropdown.Item>
    //         <Dropdown.Item className='dash__tableDropItem'>
    //           <i class='fa-solid fa-pen me-2' />
    //           Edit Profile
    //         </Dropdown.Item>
    //         <Dropdown.Item className='dash__tableDropItem'>
    //           <i class='fa-solid fa-book me-2' />
    //           DESC Record
    //         </Dropdown.Item>
    //       </Dropdown.Menu>
    //     </Dropdown>
    //   ),
    // },
    // {
    //   id: (
    //     <div className='d-flex justify-content-center align-items-center gap-2'>
    //       <button className='dash__newBatch rounded-1'>New</button>
    //       <p className='exchange_table_values m-0'>IMD-101-07-121</p>
    //     </div>
    //   ),
    //   name: (
    //     <p className='exchange_table_values m-0 text-center'>Anbalagan A</p>
    //   ),
    //   course: (
    //     <p className='exchange_table_values m-0 text-center'>UX/UI Design</p>
    //   ),
    //   location: (
    //     <p className='exchange_table_values m-0 exchange_table_address text-center'>
    //       Madurai
    //     </p>
    //   ),
    //   mobile: (
    //     <p className='exchange_table_values m-0 text-center'>+91 8012345663</p>
    //   ),
    //   gender: <p className='exchange_table_values m-0 text-center'>Male</p>,
    //   action: (
    //     <Dropdown className='dash__tableDrop'>
    //       <Dropdown.Toggle id='dropdown-basic'>
    //         <i class='fa-solid fa-ellipsis-vertical'></i>
    //       </Dropdown.Toggle>

    //       <Dropdown.Menu className='dash__tableDropMenu'>
    //         <Dropdown.Item className='dash__tableDropItem'>
    //           <i class='fa-solid fa-user me-2' /> View Profile
    //         </Dropdown.Item>
    //         <Dropdown.Item className='dash__tableDropItem'>
    //           <i class='fa-solid fa-pen me-2' />
    //           Edit Profile
    //         </Dropdown.Item>
    //         <Dropdown.Item className='dash__tableDropItem'>
    //           <i class='fa-solid fa-book me-2' />
    //           DESC Record
    //         </Dropdown.Item>
    //       </Dropdown.Menu>
    //     </Dropdown>
    //   ),
    // },
    // {
    //   id: (
    //     <div className='d-flex justify-content-center align-items-center gap-2'>
    //       <button className='dash__newBatch rounded-1'>New</button>
    //       <p className='exchange_table_values m-0'>IMD-101-07-121</p>
    //     </div>
    //   ),
    //   name: (
    //     <p className='exchange_table_values m-0 text-center'>Anbalagan A</p>
    //   ),
    //   course: (
    //     <p className='exchange_table_values m-0 text-center'>UX/UI Design</p>
    //   ),
    //   location: (
    //     <p className='exchange_table_values m-0 exchange_table_address text-center'>
    //       Madurai
    //     </p>
    //   ),
    //   mobile: (
    //     <p className='exchange_table_values m-0 text-center'>+91 8012345663</p>
    //   ),
    //   gender: <p className='exchange_table_values m-0 text-center'>Male</p>,
    //   action: (
    //     <Dropdown className='dash__tableDrop'>
    //       <Dropdown.Toggle id='dropdown-basic'>
    //         <i class='fa-solid fa-ellipsis-vertical'></i>
    //       </Dropdown.Toggle>

    //       <Dropdown.Menu className='dash__tableDropMenu'>
    //         <Dropdown.Item className='dash__tableDropItem'>
    //           <i class='fa-solid fa-user me-2' /> View Profile
    //         </Dropdown.Item>
    //         <Dropdown.Item className='dash__tableDropItem'>
    //           <i class='fa-solid fa-pen me-2' />
    //           Edit Profile
    //         </Dropdown.Item>
    //         <Dropdown.Item className='dash__tableDropItem'>
    //           <i class='fa-solid fa-book me-2' />
    //           DESC Record
    //         </Dropdown.Item>
    //       </Dropdown.Menu>
    //     </Dropdown>
    //   ),
    // },
    // {
    //   id: (
    //     <div className='d-flex justify-content-center align-items-center gap-2'>
    //       <button className='dash__newBatch rounded-1'>New</button>
    //       <p className='exchange_table_values m-0'>IMD-101-07-121</p>
    //     </div>
    //   ),
    //   name: (
    //     <p className='exchange_table_values m-0 text-center'>Anbalagan A</p>
    //   ),
    //   course: (
    //     <p className='exchange_table_values m-0 text-center'>UX/UI Design</p>
    //   ),
    //   location: (
    //     <p className='exchange_table_values m-0 exchange_table_address text-center'>
    //       Madurai
    //     </p>
    //   ),
    //   mobile: (
    //     <p className='exchange_table_values m-0 text-center'>+91 8012345663</p>
    //   ),
    //   gender: <p className='exchange_table_values m-0 text-center'>Male</p>,
    //   action: (
    //     <Dropdown className='dash__tableDrop'>
    //       <Dropdown.Toggle id='dropdown-basic'>
    //         <i class='fa-solid fa-ellipsis-vertical'></i>
    //       </Dropdown.Toggle>

    //       <Dropdown.Menu className='dash__tableDropMenu'>
    //         <Dropdown.Item className='dash__tableDropItem'>
    //           <i class='fa-solid fa-user me-2' /> View Profile
    //         </Dropdown.Item>
    //         <Dropdown.Item className='dash__tableDropItem'>
    //           <i class='fa-solid fa-pen me-2' />
    //           Edit Profile
    //         </Dropdown.Item>
    //         <Dropdown.Item className='dash__tableDropItem'>
    //           <i class='fa-solid fa-book me-2' />
    //           DESC Record
    //         </Dropdown.Item>
    //       </Dropdown.Menu>
    //     </Dropdown>
    //   ),
    // },
    // {
    //   id: (
    //     <div className='d-flex justify-content-center align-items-center gap-2'>
    //       <button className='dash__newBatch rounded-1'>New</button>
    //       <p className='exchange_table_values m-0'>IMD-101-07-121</p>
    //     </div>
    //   ),
    //   name: (
    //     <p className='exchange_table_values m-0 text-center'>Anbalagan A</p>
    //   ),
    //   course: (
    //     <p className='exchange_table_values m-0 text-center'>UX/UI Design</p>
    //   ),
    //   location: (
    //     <p className='exchange_table_values m-0 exchange_table_address text-center'>
    //       Madurai
    //     </p>
    //   ),
    //   mobile: (
    //     <p className='exchange_table_values m-0 text-center'>+91 8012345663</p>
    //   ),
    //   gender: <p className='exchange_table_values m-0 text-center'>Male</p>,
    //   action: (
    //     <Dropdown className='dash__tableDrop'>
    //       <Dropdown.Toggle id='dropdown-basic'>
    //         <i class='fa-solid fa-ellipsis-vertical'></i>
    //       </Dropdown.Toggle>

    //       <Dropdown.Menu className='dash__tableDropMenu'>
    //         <Dropdown.Item className='dash__tableDropItem'>
    //           <i class='fa-solid fa-user me-2' /> View Profile
    //         </Dropdown.Item>
    //         <Dropdown.Item className='dash__tableDropItem'>
    //           <i class='fa-solid fa-pen me-2' />
    //           Edit Profile
    //         </Dropdown.Item>
    //         <Dropdown.Item className='dash__tableDropItem'>
    //           <i class='fa-solid fa-book me-2' />
    //           DESC Record
    //         </Dropdown.Item>
    //       </Dropdown.Menu>
    //     </Dropdown>
    //   ),
    // },
    // {
    //   id: (
    //     <div className='d-flex justify-content-center align-items-center gap-2'>
    //       <button className='dash__newBatch rounded-1'>New</button>
    //       <p className='exchange_table_values m-0'>IMD-101-07-121</p>
    //     </div>
    //   ),
    //   name: (
    //     <p className='exchange_table_values m-0 text-center'>Anbalagan A</p>
    //   ),
    //   course: (
    //     <p className='exchange_table_values m-0 text-center'>UX/UI Design</p>
    //   ),
    //   location: (
    //     <p className='exchange_table_values m-0 exchange_table_address text-center'>
    //       Madurai
    //     </p>
    //   ),
    //   mobile: (
    //     <p className='exchange_table_values m-0 text-center'>+91 8012345663</p>
    //   ),
    //   gender: <p className='exchange_table_values m-0 text-center'>Male</p>,
    //   action: (
    //     <Dropdown className='dash__tableDrop'>
    //       <Dropdown.Toggle id='dropdown-basic'>
    //         <i class='fa-solid fa-ellipsis-vertical'></i>
    //       </Dropdown.Toggle>

    //       <Dropdown.Menu className='dash__tableDropMenu'>
    //         <Dropdown.Item className='dash__tableDropItem'>
    //           <i class='fa-solid fa-user me-2' /> View Profile
    //         </Dropdown.Item>
    //         <Dropdown.Item className='dash__tableDropItem'>
    //           <i class='fa-solid fa-pen me-2' />
    //           Edit Profile
    //         </Dropdown.Item>
    //         <Dropdown.Item className='dash__tableDropItem'>
    //           <i class='fa-solid fa-book me-2' />
    //           DESC Record
    //         </Dropdown.Item>
    //       </Dropdown.Menu>
    //     </Dropdown>
    //   ),
    // },
    // {
    //   id: (
    //     <div className='d-flex justify-content-center align-items-center gap-2'>
    //       <button className='dash__newBatch rounded-1'>New</button>
    //       <p className='exchange_table_values m-0'>IMD-101-07-121</p>
    //     </div>
    //   ),
    //   name: (
    //     <p className='exchange_table_values m-0 text-center'>Anbalagan A</p>
    //   ),
    //   course: (
    //     <p className='exchange_table_values m-0 text-center'>UX/UI Design</p>
    //   ),
    //   location: (
    //     <p className='exchange_table_values m-0 exchange_table_address text-center'>
    //       Madurai
    //     </p>
    //   ),
    //   mobile: (
    //     <p className='exchange_table_values m-0 text-center'>+91 8012345663</p>
    //   ),
    //   gender: <p className='exchange_table_values m-0 text-center'>Male</p>,
    //   action: (
    //     <Dropdown className='dash__tableDrop'>
    //       <Dropdown.Toggle id='dropdown-basic'>
    //         <i class='fa-solid fa-ellipsis-vertical'></i>
    //       </Dropdown.Toggle>

    //       <Dropdown.Menu className='dash__tableDropMenu'>
    //         <Dropdown.Item className='dash__tableDropItem'>
    //           <i class='fa-solid fa-user me-2' /> View Profile
    //         </Dropdown.Item>
    //         <Dropdown.Item className='dash__tableDropItem'>
    //           <i class='fa-solid fa-pen me-2' />
    //           Edit Profile
    //         </Dropdown.Item>
    //         <Dropdown.Item className='dash__tableDropItem'>
    //           <i class='fa-solid fa-book me-2' />
    //           DESC Record
    //         </Dropdown.Item>
    //       </Dropdown.Menu>
    //     </Dropdown>
    //   ),
    // },
    // {
    //   id: (
    //     <div className='d-flex justify-content-center align-items-center gap-2'>
    //       <button className='dash__newBatch rounded-1'>New</button>
    //       <p className='exchange_table_values m-0'>IMD-101-07-121</p>
    //     </div>
    //   ),
    //   name: (
    //     <p className='exchange_table_values m-0 text-center'>Anbalagan A</p>
    //   ),
    //   course: (
    //     <p className='exchange_table_values m-0 text-center'>UX/UI Design</p>
    //   ),
    //   location: (
    //     <p className='exchange_table_values m-0 exchange_table_address text-center'>
    //       Madurai
    //     </p>
    //   ),
    //   mobile: (
    //     <p className='exchange_table_values m-0 text-center'>+91 8012345663</p>
    //   ),
    //   gender: <p className='exchange_table_values m-0 text-center'>Male</p>,
    //   action: (
    //     <Dropdown className='dash__tableDrop'>
    //       <Dropdown.Toggle id='dropdown-basic'>
    //         <i class='fa-solid fa-ellipsis-vertical'></i>
    //       </Dropdown.Toggle>

    //       <Dropdown.Menu className='dash__tableDropMenu'>
    //         <Dropdown.Item className='dash__tableDropItem'>
    //           <i class='fa-solid fa-user me-2' /> View Profile
    //         </Dropdown.Item>
    //         <Dropdown.Item className='dash__tableDropItem'>
    //           <i class='fa-solid fa-pen me-2' />
    //           Edit Profile
    //         </Dropdown.Item>
    //         <Dropdown.Item className='dash__tableDropItem'>
    //           <i class='fa-solid fa-book me-2' />
    //           DESC Record
    //         </Dropdown.Item>
    //       </Dropdown.Menu>
    //     </Dropdown>
    //   ),
    // },
  ],
};

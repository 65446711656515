import { format, isToday } from 'date-fns';
import config from "../config/config";
const CryptoJS = require("crypto-js");

export const isEmpty = (value) =>
  value === undefined ||
  value === null ||
  (typeof value === "object" && Object.keys(value).length === 0) ||
  (typeof value === "string" && value.trim().length === 0) ||
  (typeof value === "string" && value === "0") ||
  (typeof value === "number" && value === 0) ||
  (typeof value === "boolean" && value === false);

export function encryptData(data) {
  try {
    return CryptoJS.AES.encrypt(JSON.stringify(data), config.Secret).toString();
  } catch (e) {
    console.log("error on encryptData", e);
  }
}

export function decryptData(cipherText) {
  try {
    const bytes = CryptoJS.AES.decrypt(cipherText, config.Secret);
    return JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
  } catch (e) {
    console.log("error on decryptData", e);
  }
}

export function obfuscateEmail(email) {
  const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  if (!emailPattern.test(email)) {
    return email;
  }
  const [localPart, domainPart] = email.split("@");

  let obfuscatedLocalPart;
  if (localPart.length > 3) {
    obfuscatedLocalPart = localPart.substring(0, 3) + "***";
  } else {
    obfuscatedLocalPart = localPart + "***";
  }

  return `${obfuscatedLocalPart}@${domainPart}`;
}

export const setLocalStorage = (id, data) => localStorage.setItem(id, data);
export const getLocalStorage = (id) => localStorage.getItem(id);

export const changeOn = async (id, value, callBack, callBack2) => {
  var e = {
    target: {
      id,
      value,
    },
  };
  callBack(e);
  if (callBack2) callBack2({});
};



export function formatDate(date) {
  const formattedTime = format(date, 'h:mm aa'); // '9:35 am'
  return isToday(date) ? `Today ${formattedTime}` : formattedTime;
}


export const destination = {
  name : "Student Details",
  email : "Student Details",
  nationality : "Student Details",
  gender : "Student Details",
  DOB : "Student Details",
  IDno : "Student Details",
  mobileNumber : "Student Details",
  maritalStatus : "Student Details",
  bloodGroup : "Student Details",
  aadharNum : "Student Details",
  permanentAddress : "Residential Details",
  currentAddress : "Residential Details",
  city : "Residential Details",
  state : "Residential Details",
  pincode : "Residential Details",
  fatherName : "Parent Details",
  fatherNumber : "Parent Details",
  motherName : "Parent Details",
  motherNumber : "Parent Details",
  guardianName : "Parent Details",
  guardianNumber : "Parent Details",
  courseName : "Course Details",
  degree : "Course Details",
  employmentStatus : "Course Details",
  source : "Course Details",
}

export const getCatDetails = (key)=>{
  try {
    return destination[key] || "Student Details"
  } catch (e) {
    console.log("erron on getCatDetails",e);
    return "Student Details"
  }
}
import React from 'react';
import { allImages } from '../Images';
import { NavLink, useNavigate } from 'react-router-dom';

const Sidebar = () => {
  const navigate = useNavigate();
  const onLogOut = () => {
    localStorage.clear();
    navigate('/');
  };
  return (
    <>
      <div className='sidebar rounded-1 py-5 h-100 d-flex flex-column justify-content-between'>
        <div className='sidebar__top'>
          <NavLink to='/dashboard'>
            <div className='sidebar__singleNav pointer d-flex flex-column justify-content-start align-items-center mb-4'>
              <img src={allImages.home} className='img-fluid' alt='' />
              <p className='m-0 sidebar__label mt-1 text-center'>Home</p>
            </div>
          </NavLink>

          <div
            className='sidebar__singleNav pointer d-flex flex-column justify-content-start align-items-center mb-4'
            onClick={() => navigate('/requests')}
          >
            <img src={allImages.addStudent} className='img-fluid' alt='' />
            <p className='m-0 sidebar__label mt-1 text-center'>Requests</p>
          </div>

          <div className='sidebar__singleNav pointer d-flex flex-column justify-content-start align-items-center mb-4'>
            <img src={allImages.brush} className='img-fluid' alt='' />
            <p className='m-0 sidebar__label mt-1 text-center'>Edit</p>
          </div>

          <div className='sidebar__singleNav pointer d-flex flex-column justify-content-start align-items-center mb-4'>
            <img src={allImages.user} className='img-fluid' alt='' />
            <p className='m-0 sidebar__label mt-1 text-center'>Users</p>
          </div>

          <div className='sidebar__singleNav pointer d-flex flex-column justify-content-start align-items-center mb-4'>
            <img src={allImages.star} className='img-fluid' alt='' />
            <p className='m-0 sidebar__label mt-1 text-center'>Star</p>
          </div>

          <div className='sidebar__singleNav pointer d-flex flex-column justify-content-start align-items-center mb-4'>
            <a href='/sampleData.xlsx' download='SampleData.xlsx'>
              <button className='sidebar__excelBtn'>
                <i class='fa-solid fa-file-excel' />
              </button>
            </a>
            <p className='m-0 sidebar__label mt-1 text-center'>Excel File</p>
          </div>
          {/* <div className='sidebar__singleNav pointer d-flex flex-column justify-content-start align-items-center mb-4'>
            <i class='fa-solid fa-bell sidebar__notifier' />
            <p className='m-0 sidebar__label mt-1 text-center'>Requests</p>
          </div> */}
        </div>

        <div
          onClick={() => onLogOut()}
          className='sidebar__singleNav pointer d-flex flex-column justify-content-start align-items-center mb-4'
        >
          <img src={allImages.logout} className='img-fluid' alt='' />
          <p className='m-0 sidebar__label mt-1 text-center'>Logout</p>
        </div>
      </div>
    </>
  );
};

export default Sidebar;

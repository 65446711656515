import axios from 'axios';
import config from '../config/config'


export const adminLogin = async (data)=>{
    console.log('data',data);
    try{
        const resp = await axios({
            'method':'GET',
            'url':`${config.AdminAPI}/adminLogin`,
            'params':data
        })
        console.log("returned response",resp.data)
        return resp.data;
    }catch(e){
        console.log("error on axios",e);
    }
} 

export const resendOTP = async (data)=>{
    console.log('data',data);
    try{
        const resp = await axios({
            'method':'POST',
            'url':`${config.AdminAPI}/resendOTP`,
            'data':data
        })
        console.log("returned response",resp.data)
        return resp.data;
    }catch(e){
        console.log("error on axios",e);
    }
} 

export const getAllNews = async (data)=>{
    console.log('data',data);
    try{
        const resp = await axios({
            'method':'GET',
            'url':`${config.AdminAPI}/getAllNews`
        })
        console.log("returned response",resp.data)
        return resp.data;
    }catch(e){
        console.log("error on axios",e);
    }
} 

export const saveNews = async (data)=>{
    console.log('data',data);
    try{
        const resp = await axios({
            'method':'POST',
            'url':`${config.AdminAPI}/saveNews`,
            data
        })
        console.log("returned response",resp.data)
        return resp.data;
    }catch(e){
        console.log("error on axios",e);
    }
} 


export const deleteNews = async (data)=>{
    console.log('data',data);
    try{
        const resp = await axios({
            'method':'POST',
            'url':`${config.AdminAPI}/deleteNews`,
            data
        })
        console.log("returned response",resp.data)
        return resp.data;
    }catch(e){
        console.log("error on axios",e);
    }
} 

export const editNews = async (data)=>{
    console.log('data',data);
    try{
        const resp = await axios({
            'method':'POST',
            'url':`${config.AdminAPI}/editNews`,
            data
        })
        console.log("returned response",resp.data)
        return resp.data;
    }catch(e){
        console.log("error on axios",e);
    }
} 

export const forgetPasswordmail = async (data)=>{
    console.log('data',data);
    try{
        const resp = await axios({
            'method':'POST',
            'url':`${config.AdminAPI}/forgetPasswordmail`,
            data
        })
        console.log("returned response",resp.data)
        return resp.data;
    }catch(e){
        console.log("error on axios",e);
    }
}

export const changeAdminPassword = async (data)=>{
    console.log('data',data);
    try{
        const resp = await axios({
            'method':'POST',
            'url':`${config.AdminAPI}/changeAdminPassword`,
            data
        })
        console.log("returned response",resp.data)
        return resp.data;
    }catch(e){
        console.log("error on axios",e);
    }
}

export const getPlacedStudents = async (data)=>{
    console.log('data',data);
    try{
        const resp = await axios({
            'method':'GET',
            'url':`${config.AdminAPI}/getPlacedStudents`,
            params:data
        })
        console.log("returned response",resp.data)
        return resp.data;
    }catch(e){
        console.log("error on axios",e);
    }
}

export const savePlacedStudents = async (data)=>{
    console.log('data',data);
    try{
        const resp = await axios({
            'method':'POST',
            'url':`${config.AdminAPI}/savePlacedStudents`,
            data,
        },{
            headers:{
                'Content-Type': 'multipart/form-data'
            }
        })
        console.log("returned response",resp.data)
        return resp.data;
    }catch(e){
        console.log("error on axios",e);
    }
}


export const getBanners = async (data)=>{
    console.log('data',data);
    try{
        const resp = await axios({
            'method':'GET',
            'url':`${config.AdminAPI}/getBanners`,
            params:data
        })
        console.log("returned response",resp.data)
        return resp.data;
    }catch(e){
        console.log("error on axios",e);
    }
}

export const saveBanners = async (data)=>{
    console.log('data',data);
    try{
        const resp = await axios({
            'method':'POST',
            'url':`${config.AdminAPI}/saveBanners`,
            data,
        },{
            headers:{
                'Content-Type': 'multipart/form-data'
            }
        })
        console.log("returned response",resp.data)
        return resp.data;
    }catch(e){
        console.log("error on axios",e);
    }
}

export const getStudentChanges = async (data)=>{
    console.log('data',data);
    try{
        const resp = await axios({
            'method':'GET',
            'url':`${config.AdminAPI}/getStudentChanges`,
            params:data
        })
        console.log("returned response",resp.data)
        return resp.data;
    }catch(e){
        console.log("error on axios",e);
    }
}
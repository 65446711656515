import React, { useEffect, useState } from 'react';
import Layout from '../Layout/Layout';
import { Col, Dropdown, Row } from 'react-bootstrap';
import { allImages } from '../Images';
import { useNavigate } from 'react-router-dom';
import { getPlacedStudents, savePlacedStudents } from '../axios/admin.axios';
import { toast } from 'react-toastify';
import config from '../config/config';
import { isEmpty } from '../config/common';
import DeleteSlideModal from '../Modals/DeleteSlideModal';

const AddPlacedStudents = () => {
  const [activeSlide, setActiveSlide] = useState(null);
  const navigate = useNavigate();
  const initial = {
    image: '',
    studentName: '',
    courseName: '',
    companyName: '',
    selected: false,
  };
  const [slideArr, setSlideArr] = useState([]);

  const onChangeFunc = (e, ind) => {
    const { id, value, files } = e.target;
    slideArr[ind][id] = files ? files[0] : value;
    setSlideArr([...slideArr]);
  };

  console.log('slideArrslideArr', slideArr);

  const onSubmitFunc = async () => {
    const getValid = slideArr.map((val) => {
      console.log('getValidddd', Object.values(val), val);
      return (
        val.image !== '' &&
        !isEmpty(val.studentName) &&
        !isEmpty(val.courseName) &&
        !isEmpty(val.companyName)
      );
    });
    console.log('getValid', getValid);
    if (getValid.includes(false)) return toast.error('Some datas are missing');
    const formData = new FormData();

    slideArr.forEach((item, index) => {
      formData.append(`data[${index}][companyName]`, item.companyName);
      formData.append(`data[${index}][courseName]`, item.courseName);
      formData.append(`data[${index}][image]`, item.image);
      formData.append(`data[${index}][name]`, item.name);
      formData.append(`data[${index}][studentName]`, item.studentName);
      formData.append(`data[${index}][selected]`, item.selected);
    });

    formData.append('Length', slideArr.length);

    const resp = await savePlacedStudents(formData);
    toast[resp?.success ?? 'error'](resp?.msg ?? 'Error');
    window.location.reload();
  };

  const onForUploadSelect = (i) => {
    slideArr[i].selected = !slideArr[i].selected;
    setSlideArr([...slideArr]);
  };

  useEffect(() => {
    getPlacedStu();
  }, []);

  const getPlacedStu = async () => {
    const resp = await getPlacedStudents();
    setSlideArr(resp?.data || slideArr);
  };

  const deleteSlide = (i) => {
    var data = slideArr.filter((val, ii) => ii !== (i || activeSlide));
    console.log('data', data);
    setSlideArr([...data]);
    handleCloseDeleteSlide();
    setActiveSlide(null)
  };

  // modal states

  const [showDeleteSlide, setShowDeleteSlide] = useState(false);
  const handleCloseDeleteSlide = () => setShowDeleteSlide(false);
  const handleShowDeleteSlide = () => setShowDeleteSlide(true);
  const handleDeleteSlide = () => {
    // deleteSlide(slideArr.length - i - 1);
  };
  return (
    <>
      <Layout>
        <div className='dash__filtersHlder d-flex justify-content-end align-items-center gap-4 mb-3'>
          <Dropdown className='dash__filterDrop'>
            <Dropdown.Toggle id='dropdown-basic'>
              Placed Students
            </Dropdown.Toggle>

            <Dropdown.Menu>
              <Dropdown.Item onClick={() => navigate('/addBanner')}>
                Banners
              </Dropdown.Item>
              <Dropdown.Item onClick={() => navigate('/addPlacedStudents')}>
                Placed Students
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </div>

        <Row className='h-100'>
          <Col lg={4}>
            <div className='addons__slideHolder p-3 h-100'>
              <div className='addons__slideTop h-100'>
                <h5 className='m-0 addons__leftTitle'>
                  Upload Students Profile
                </h5>

                <div className='addons__slideScroller'>
                  {slideArr.length !== 0 &&
                    slideArr
                      .slice()
                      .reverse()
                      .map((val, i) => {
                        return (
                          <div
                            key={i}
                            className={`addons__singleSlide position-relative p-2 mt-3 d-flex justify-content-between align-items-center ${
                              val?.selected ? 'active' : ''
                            }`}
                          >
                            <div
                              className='flex-grow-1'
                              onClick={() =>
                                onForUploadSelect(slideArr.length - i - 1)
                              }
                            >
                              <p className='m-0 addons__slideLabel'>
                                Slide {slideArr.length - i}
                              </p>
                            </div>
                            <button
                              className='addons__closer'
                              // onClick={() =>
                              //   deleteSlide(slideArr.length - i - 1)
                              // }
                              onClick={() => {
                                handleShowDeleteSlide();
                                setActiveSlide(slideArr.length - i - 1);
                              }}
                            >
                              <i className='fa-solid fa-xmark' />
                            </button>
                          </div>
                        );
                      })}
                </div>

                <div className='addons__bottomBtns mt-4 d-flex gap-2 justify-content-center align-items-center'>
                  <button
                    className='primary__btn whiteSpace__noWrapper rounded-1 flex-grow-1'
                    onClick={() => setSlideArr([...slideArr, initial])}
                  >
                    Add Slides
                  </button>
                  <button
                    className='primary__btn rounded-1 flex-grow-1'
                    onClick={() => onSubmitFunc()}
                  >
                    Upload
                  </button>
                </div>
              </div>
            </div>
          </Col>

          <Col lg={8}>
            <div className='addons__slidesPreview'>
              {slideArr.length !== 0 &&
                slideArr
                  .slice()
                  .reverse()
                  .map((val, i) => {
                    return (
                      <div key={i} className='addons__bannerAdder p-3 mb-4'>
                        <Row className='mt-3'>
                          <>
                            <h5 className='m-1 addons__leftTitle'>
                              Slide {slideArr.length - i}
                            </h5>
                            <Col lg={5}>
                              <div className='addons__stdtImgHolder d-flex flex-column justify-content-center align-items-center position-relative gap-3 '>
                                {val.image ? (
                                  <img
                                    alt='PlacedStudent'
                                    className='img-fluid'
                                    src={
                                      typeof val.image === 'string'
                                        ? `${config?.image_Url}/placedStudents/${val.image}`
                                        : URL.createObjectURL(val.image)
                                    }
                                  />
                                ) : (
                                  <>
                                    <button className='addons__imgUploaderBtn'>
                                      <i className='fa-solid fa-plus'></i>
                                    </button>
                                    <p className='m-0 addons__imgUploaderHint text-center'>
                                      Add Image Here
                                    </p>
                                  </>
                                )}
                                <input
                                  type='file'
                                  id='image'
                                  className='addons__bannerAdderFileInp'
                                  onChange={(e) =>
                                    onChangeFunc(e, slideArr.length - i - 1)
                                  }
                                />
                              </div>
                            </Col>
                            <Col lg={7}>
                              <div className='d-flex flex-column justify-content-between h-100'>
                                <div>
                                  <label className='cmn__inputLabel mb-2'>
                                    Student Name
                                  </label>
                                  <input
                                    type='text'
                                    className='addons__cmnInput p-2 mb-3'
                                    placeholder='Student Name'
                                    id='studentName'
                                    onChange={(e) =>
                                      onChangeFunc(e, slideArr.length - i - 1)
                                    }
                                    value={val.studentName}
                                  />

                                  <label className='cmn__inputLabel mb-2'>
                                    Course Name
                                  </label>
                                  <input
                                    type='text'
                                    className='addons__cmnInput p-2 mb-3'
                                    placeholder='Course Name'
                                    id='courseName'
                                    value={val.courseName}
                                    onChange={(e) =>
                                      onChangeFunc(e, slideArr.length - i - 1)
                                    }
                                  />

                                  <label className='cmn__inputLabel mb-2'>
                                    Company Name
                                  </label>
                                  <input
                                    type='text'
                                    className='addons__cmnInput p-2 mb-3'
                                    placeholder='Company Name'
                                    id='companyName'
                                    value={val.companyName}
                                    onChange={(e) =>
                                      onChangeFunc(e, slideArr.length - i - 1)
                                    }
                                  />
                                </div>

                                <div className='d-flex justify-content-end align-items-center gap-4'>
                                  <button
                                    className='otpVerify__plainbutton'
                                    // onClick={() =>
                                    //   deleteSlide(slideArr.length - i - 1)
                                    // }
                                  >
                                    Cancel
                                  </button>
                                  <button className='primary__btn rounded-1'>
                                    Upload
                                  </button>
                                </div>
                              </div>
                            </Col>
                          </>
                        </Row>
                      </div>
                    );
                  })}
            </div>
          </Col>
        </Row>
      </Layout>

      {/* modals */}
      <DeleteSlideModal
        show={showDeleteSlide}
        handleClose={handleCloseDeleteSlide}
        deleteSlide={deleteSlide}
      />
      {/* end of modals */}
    </>
  );
};

export default AddPlacedStudents;

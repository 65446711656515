import React, { useState } from 'react';
import Select from 'react-select';
import { changeOn } from '../config/common';

function ResedentialDetails(props) {
  const [selectedOption, setSelectedOption] = useState(null);
  const options = [
    { value: 'tamilnadu', label: 'TamilNadu' },
    { value: 'kerala', label: 'Kerala' },
    { value: 'andhra', label: 'Andhra Pradesh' },
    { value: 'karnataka', label: 'Karnataka' },
  ];

  const styles = {
    option: (styles, { isFocused, isSelected, isHovered }) => ({
      ...styles,
      color: '#fff',
      background: isFocused
        ? '#0081F9'
        : isSelected
        ? '#74c69d'
        : isHovered
        ? '#0081F9'
        : '#fff',

      zIndex: 1,
      cursor: 'pointer',
      fontSize: '13px',
    }),

    option: (styles, { isFocused, isSelected, isHovered }) => {
      // const color = chroma(data.color);

      return {
        ...styles,
        backgroundColor: isHovered
          ? '#0081F9'
          : isSelected
          ? '#74c69d'
          : isFocused
          ? '#0081F9'
          : '#fff',
        cursor: 'pointer',
        color: isHovered
          ? '#fff'
          : isSelected
          ? '#000'
          : isFocused
          ? '#fff'
          : '#000',
        fontSize: '13px',
      };
    },
    valueContainer: (provided, { isFocused, isSelected, isHovered }) => ({
      ...provided,
      height: '33px',
      border: '1px solid #282828',
      backgroundColor: isHovered
        ? 'transperant'
        : isSelected
        ? 'transperant'
        : isFocused
        ? 'transperant'
        : 'transperant',
      // border: "1px solid rgba(34, 34, 34, 0.32)",
      // borderRadius: 5,
      fontSize: '13px',
      color: '#000',
    }),
    control: (provided, { isFocused, isSelected, isHovered }) => ({
      ...provided,
      height: '33px',
      border: '1px solid #00CABA',
      borderRadius: 5,
      backgroundColor: isHovered
        ? 'transperant'
        : isSelected
        ? 'transperant'
        : isFocused
        ? 'transperant'
        : 'transperant',
      // backgroundColor: "#fff",
      border: 'none',
      outline: 'none',
      boxShadow: 'none',
      color: '#000',
    }),
    indicatorSeparator: (provided, state) => ({
      ...provided,
      display: 'none',
    }),
    indicatorsContainer: (provided, state) => ({
      ...provided,
      height: '33px',
      position: 'absolute',
      right: 0,
      top: 0,
      color: '#6C6A81',
      // display: 'none',
    }),
    singleValue: (provided, state) => ({
      ...provided,
      color: '#000',
    }),
    menuList: (base) => ({
      ...base,
      // kill the white space on first and last option
      padding: 0,
    }),
  };

  const { error, formData } = props;

  return (
    <>
      <h5 className='text-center singIn__formTitle mb-5'>
        Residential Details
      </h5>
      <div className='mb-3'>
        <label className='cmn__inputLabel mb-2'>Permanent Address</label>
        <div className='cmn__inputHolder py-1 px-2'>
          <input
            type='text'
            placeholder='Enter Permanent Address'
            id='permanentAddress'
            className='signIn__inputter '
            value={formData?.permanentAddress}
            onChange={(e) => props?.onChangeFunc(e)}
          />
        </div>
        <p className='m-0 cmn__errorHint'>{error?.permanentAddress}</p>
      </div>
      <div className='mb-3'>
        <label className='cmn__inputLabel mb-2'>Current Address</label>
        <div className='cmn__inputHolder py-1 px-2'>
          <input
            type='text'
            placeholder='Enter Current Address'
            className='signIn__inputter '
            value={formData?.currentAddress}
            onChange={(e) => props?.onChangeFunc(e)}
            id='currentAddress'
          />
        </div>
        <p className='m-0 cmn__errorHint'>{error?.currentAddress}</p>
      </div>
      <div className='mb-3'>
        <label className='cmn__inputLabel mb-2'>City</label>
        <div className='cmn__inputHolder py-1 px-2'>
          <input
            type='text'
            placeholder='Enter City'
            className='signIn__inputter '
            value={formData?.city}
            onChange={(e) => props?.onChangeFunc(e)}
            id='city'
          />
        </div>
        <p className='m-0 cmn__errorHint'>{error?.city}</p>
      </div>
      <div className='mb-3'>
        <label className='cmn__inputLabel mb-2'>State</label>
        <div className='studDtls__selectHolder position-relative'>
          <Select
            placeholder='Selct State'
            defaultValue={selectedOption}
            value={
              formData?.state
                ? { value: formData?.state, label: formData?.state }
                : null
            }
            // onChange={setSelectedOption}
            options={options}
            styles={styles}
            id='state'
            onChange={(e) => changeOn('state', e.value, props?.onChangeFunc)}
          />
        </div>
        <p className='m-0 cmn__errorHint'>{error?.state}</p>
      </div>

      <div className='mb-3'>
        <label className='cmn__inputLabel mb-2'>Pincode</label>
        <div className='cmn__inputHolder py-1 px-2'>
          <input
            type='text'
            placeholder='Enter Pincode'
            className='signIn__inputter'
            id='pincode'
            value={formData?.pincode}
            onChange={(e) => props?.onChangeFunc(e)}
          />
        </div>
        <p className='m-0 cmn__errorHint'>{error?.pincode}</p>
      </div>
    </>
  );
}

export default ResedentialDetails;

import React, { useEffect, useState } from 'react';
import { Col, Modal, Row } from 'react-bootstrap';
// import DatePicker from 'react-date-picker';
import Calendar from 'react-calendar';

const CalendarModal = ({ show, handleClose,getData,setDate,date }) => {

  const sevenDay = new Date(new Date().setDate(new Date().getDate() - 7));
  const [dateArr,setDateArr]=useState([new Date(sevenDay.setHours(1,0,0,0)),new Date(new Date().setHours(23,59,59,99))])
  console.log("dateArr",dateArr);

  useEffect(()=>{
    onDateChange(date);
  },[date])
  const onDateChange = (props)=>{
    if(typeof props == "number"){
      setDate(props)
      const arr = []
      if(props === 1) {
        arr.push(new Date(new Date().setHours(0,0,0,0)),new Date(new Date().setHours(23,59,59,99)))
      }else{
        const desiredDay = new Date(new Date().setDate(new Date().getDate() - props));
        arr.push(new Date(desiredDay.setHours(0,0,0,0)),new Date(new Date().setHours(23,59,59,99)))
      }
      setDateArr([...arr]);
    }else setDateArr(props)
    
  }

  return (
    <>
      <Modal
        centered
        size='lg'
        show={show}
        onHide={()=>{handleClose();getData(dateArr)}}
        backdrop='static'
        keyboard={false}
      >
        <Modal.Header closeButton>
          <h5 className='m-0 cmn__modalTitle'>Select Date</h5>
        </Modal.Header>
        <Modal.Body>
          <Row className='align-items-start h-100'>
            <Col lg={2}>
              <div className='req__daysFilter'>
                <ul>
                  <li className={`py-1 px-2 mb-2 pointer ${date === 1 ? "selectedDate":""}`} onClick={()=>onDateChange(1)} >Today</li>
                  <li className={`py-1 px-2 mb-2 pointer ${date === 7 ? "selectedDate":""}`} onClick={()=>onDateChange(7)}>Last 7 Days</li>
                  <li className={`py-1 px-2 mb-2 pointer ${date === 30 ? "selectedDate":""}`}onClick={()=>onDateChange(30)}>Last 30 Days</li>
                  <li className={`py-1 px-2 mb-2 pointer ${date === 90 ? "selectedDate":""}`}onClick={()=>onDateChange(90)}>Lasy 90 Days</li>
                  {/* <li className='py-1 px-2 mb-2 pointer'>2021</li>
                  <li className='py-1 px-2 mb-2 pointer'>2020</li>
                  <li className='py-1 px-2 mb-2 pointer'>2019</li> */}
                </ul>
              </div>
            </Col>
            <Col lg={10}>
              {/* <DatePicker
                onChange={onChange}
                showLeadingZeros={true}
                isOpen={true}
                value={value}
              /> */}
              <div className='req__calendarHolder d-flex justify-content-center'>
                <Calendar
                  onChange={onDateChange}
                  value={dateArr}
                  selectRange={true}
                  maxDate={new Date( )}
                />
              </div>
            </Col>
          </Row>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default CalendarModal;

import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import Header from './Header';
import Sidebar from './Sidebar';
import News from '../Components/News';
import PlacedStudents from '../Components/PlacedStudents';

const Layout = ({ children }) => {
  return (
    <>
      <Container fluid className='p-0 common__inner'>
        <Header />
        <div className='p-3'>
          <Row className='h-100'>
            <Col xl={1} xs={0} className='d-none d-xl-block'>
              <Sidebar />
            </Col>
            <Col xl={8} className='mb-4'>
              {children}
            </Col>
            <Col xl={3}>
              <Row>
                <Col xl={12} sm={6} className='mb-4'>
                  <News />
                </Col>
                <Col xl={12} sm={6}>
                  <PlacedStudents />
                </Col>
              </Row>
              {/* <div className='layout__right d-flex flex-column flex-sm-row flex-lg-column justify-content-between gap-4'>
                <div className='flex-grow-1'>
                  <News />
                </div>
                <PlacedStudents />
              </div> */}
            </Col>
          </Row>
        </div>
      </Container>
    </>
  );
};

export default Layout;

export const allImages = {
  logo: require('./Assets/Images/logo.svg').default,
  emailChecker: require('./Assets/Images/mailchecker.svg').default,
  whiteLogo: require('./Assets/Images/whiteLogo.svg').default,
  home: require('./Assets/Images/home.svg').default,
  addStudent: require('./Assets/Images/addStudent.svg').default,
  brush: require('./Assets/Images/brush.svg').default,
  user: require('./Assets/Images/user.svg').default,
  star: require('./Assets/Images/star.svg').default,
  logout: require('./Assets/Images/logout.svg').default,
  send: require('./Assets/Images/send.svg').default,
  studentOne: require('./Assets/Images/studentOne.png'),
  NoReq: require('./Assets/Images/request.svg').default,
  bannerPreview: require('./Assets/Images/preview.png'),
};

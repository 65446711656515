import React from 'react';
import { Modal } from 'react-bootstrap';

const DeleteSlideModal = ({ show, handleClose,deleteSlide }) => {
  return (
    <>
      <Modal
        centered
        show={show}
        onHide={handleClose}
        backdrop='static'
        keyboard={false}
      >
        <Modal.Header closeButton>
          <h5 className='m-0 cmn__modalTitle'>Delete Slide</h5>
        </Modal.Header>
        <Modal.Body>
          <p className='m-0 deleteSlide__hint'>
            Are you sure. You want to delte the Slide ?
          </p>

          <div className='d-flex justify-content-end align-items-center gap-4 mt-4'>
            <button className='otpVerify__plainbutton' onClick={handleClose}>
              Cancel
            </button>
            <button
              className='primary__btn rounded-1'
              onClick={()=>deleteSlide()}
            >
              Delete
            </button>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default DeleteSlideModal;

import React, { useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import Select from 'react-select';
import { changeOn } from '../config/common';

function StudentDetails(props) {
  const [phone, setPhone] = useState('');
  const [selectedOption, setSelectedOption] = useState(null);

  const { error, formData } = props;

  const options = [
    { value: 'male', label: 'Male' },
    { value: 'female', label: 'Female' },
  ];
  const styles = {
    option: (styles, { isFocused, isSelected, isHovered }) => ({
      ...styles,
      color: '#fff',
      background: isFocused
        ? '#0081F9'
        : isSelected
        ? '#74c69d'
        : isHovered
        ? '#0081F9'
        : '#fff',

      zIndex: 1,
      cursor: 'pointer',
      fontSize: '13px',
    }),

    option: (styles, { isFocused, isSelected, isHovered }) => {
      // const color = chroma(data.color);

      return {
        ...styles,
        backgroundColor: isHovered
          ? '#0081F9'
          : isSelected
          ? '#74c69d'
          : isFocused
          ? '#0081F9'
          : '#fff',
        cursor: 'pointer',
        color: isHovered
          ? '#fff'
          : isSelected
          ? '#000'
          : isFocused
          ? '#fff'
          : '#000',
        fontSize: '13px',
      };
    },
    valueContainer: (provided, { isFocused, isSelected, isHovered }) => ({
      ...provided,
      height: '33px',
      border: '1px solid #282828',
      backgroundColor: isHovered
        ? 'transperant'
        : isSelected
        ? 'transperant'
        : isFocused
        ? 'transperant'
        : 'transperant',
      // border: "1px solid rgba(34, 34, 34, 0.32)",
      // borderRadius: 5,
      fontSize: '13px',
      color: '#000',
    }),
    control: (provided, { isFocused, isSelected, isHovered }) => ({
      ...provided,
      height: '33px',
      border: '1px solid #00CABA',
      borderRadius: 5,
      backgroundColor: isHovered
        ? 'transperant'
        : isSelected
        ? 'transperant'
        : isFocused
        ? 'transperant'
        : 'transperant',
      // backgroundColor: "#fff",
      border: 'none',
      outline: 'none',
      boxShadow: 'none',
      color: '#000',
    }),
    indicatorSeparator: (provided, state) => ({
      ...provided,
      display: 'none',
    }),
    indicatorsContainer: (provided, state) => ({
      ...provided,
      height: '33px',
      position: 'absolute',
      right: 0,
      top: 0,
      color: '#6C6A81',
      // display: 'none',
    }),
    singleValue: (provided, state) => ({
      ...provided,
      color: '#000',
    }),
    menuList: (base) => ({
      ...base,
      // kill the white space on first and last option
      padding: 0,
    }),
  };

  console.log('formData', props);
  return (
    <>
      <Row>
        <h5 className='text-center singIn__formTitle mb-4'>Student Details</h5>
        <Col lg={6}>
          <div className='mb-3'>
            <label className='cmn__inputLabel mb-2'>Name</label>
            <div className='cmn__inputHolder py-1 px-2'>
              <input
                type='text'
                placeholder='Name of the student'
                className='signIn__inputter '
                id='name'
                value={formData?.name}
                onChange={(e) => props?.onChangeFunc(e)}
              />
            </div>
            <p className='m-0 cmn__errorHint'>{error?.name}</p>
          </div>
          <div className='mb-3'>
            <label className='cmn__inputLabel mb-2'>Email</label>
            <div className='cmn__inputHolder py-1 px-2'>
              <input
                type='email'
                placeholder='Email of the student'
                className='signIn__inputter '
                id='email'
                value={formData?.email}
                onChange={(e) => props?.onChangeFunc(e)}
              />
            </div>
            <p className='m-0 cmn__errorHint'>{error?.email}</p>
          </div>
          <div className='mb-3'>
            <label className='cmn__inputLabel mb-2'>Date of Birth</label>
            <div className='cmn__inputHolder py-1 px-2 pointer'>
              <input
                className='signIn__inputter signIn__dateInput'
                type='date'
                value={formData?.DOB}
                placeholder='Date of Birth'
                id='DOB'
                onChange={(e) => props?.onChangeFunc(e)}
              />
            </div>
            <p className='m-0 cmn__errorHint'>{error?.DOB}</p>
          </div>
          <div className='mb-3'>
            <label className='cmn__inputLabel mb-2'>Mobile Number</label>
            <div className='cmn__inputHolder studDtls__phoneInpHolder px-2'>
              <PhoneInput
                country={'in'}
                value={formData?.mobileNumber}
                id='mobileNumber'
                onChange={(phone) =>
                  changeOn('mobileNumber', phone, props?.onChangeFunc)
                }
              />
            </div>
            <p className='m-0 cmn__errorHint'>{error?.mobileNumber}</p>
          </div>
          <div className='mb-3'>
            <label className='cmn__inputLabel mb-2'>Blood Group</label>
            <div className='cmn__inputHolder py-1 px-2'>
              <input
                type='text'
                placeholder='Blood Group of the student'
                className='signIn__inputter '
                id='bloodGroup'
                value={formData?.bloodGroup}
                onChange={(e) => props?.onChangeFunc(e)}
              />
            </div>
            <p className='m-0 cmn__errorHint'>{error?.bloodGroup}</p>
          </div>
        </Col>
        <Col lg={6}>
          <div className='mb-3'>
            <label className='cmn__inputLabel mb-2'>Nationality</label>
            <div className='cmn__inputHolder py-1 px-2'>
              <input
                type='text'
                placeholder='Nationality of the student'
                id='nationality'
                className='signIn__inputter '
                onChange={(e) => props?.onChangeFunc(e)}
                value={formData?.nationality}
              />
            </div>
            <p className='m-0 cmn__errorHint'>{error?.nationality}</p>
          </div>
          <div className='mb-3'>
            <label className='cmn__inputLabel mb-2'>Gender</label>
            <div className='studDtls__selectHolder position-relative'>
              <Select
                defaultValue={selectedOption}
                value={{ value: formData?.gender, label: formData?.gender }}
                // onChange={setSelectedOption}
                options={options}
                styles={styles}
                id='gender'
                onChange={(e) =>
                  changeOn('gender', e.value, props?.onChangeFunc)
                }
              />
            </div>
            <p className='m-0 cmn__errorHint'>{error?.gender}</p>
          </div>
          <div className='mb-3 position-relative studDtls__selectHolder'>
            <label className='cmn__inputLabel mb-2'>ID No</label>
            <div className='cmn__inputHolder position-relative py-1 px-2'>
              <input
                type='text'
                placeholder='ID No'
                className='signIn__inputter '
                id='IDno'
                value={formData?.IDno}
                onChange={(e) => props?.onChangeFunc(e)}
              />
            </div>
            <p className='m-0 cmn__errorHint'>{error?.IDno}</p>
          </div>
          <div className='mb-3'>
            <label className='cmn__inputLabel mb-2'>Marital Status</label>
            <div className='cmn__inputHolder position-relative py-1 px-2'>
              <input
                type='text'
                placeholder='Marital Status'
                className='signIn__inputter '
                id='maritalStatus'
                value={formData?.maritalStatus}
                onChange={(e) => props?.onChangeFunc(e)}
              />
            </div>
            <p className='m-0 cmn__errorHint'>{error?.maritalStatus}</p>
          </div>
          <div className='mb-3'>
            <label className='cmn__inputLabel mb-2'>Aadhar Number</label>
            <div className='cmn__inputHolder position-relative py-1 px-2'>
              <input
                type='text'
                placeholder='Enter Aadhar Number'
                className='signIn__inputter '
                id='aadharNum'
                value={formData?.aadharNum}
                onChange={(e) => props?.onChangeFunc(e)}
              />
            </div>
            <p className='m-0 cmn__errorHint'>{error?.aadharNum}</p>
          </div>
        </Col>
      </Row>
    </>
  );
}

export default StudentDetails;

import React, { useState } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { allImages } from '../Images';
import { NavLink, useNavigate } from 'react-router-dom';
// import { Link } from 'react-router-dom'
import { FaEye } from 'react-icons/fa';
import { isEmpty } from '../config/common';
import { adminLogin } from '../axios/admin.axios';
import { toast } from 'react-toastify';
import { useDispatch } from 'react-redux';

const SignIn = () => {
  const [changer, setChanger] = useState(false);
  const navigate = useNavigate();
  const [email, setEmail] = useState('');
  const [pass, setPass] = useState('');
  const [Error, setError] = useState({});
  const vallidation = () => {
    var error = {};
    if (!email) error.email = 'Please enter mail';
    if (!pass) error.pass = 'Enter password';
    return error;
  };
  const dispatch = useDispatch()
  const onSubmit = async () => {
    const err = vallidation();
    if (!isEmpty(err)) {
      setError(err);
      toast.error('Please fix errors');
      return;
    }
    const resp = await adminLogin({ email, pass });
    console.log('resp', resp);
    if (resp?.status) {
      toast.success('Logged in successfully');
      navigate('/OtpVerify', {
        state: {
          email,
          pass,
          OTP: resp?.OTP,
          token: resp?.token,
          forgetPass: false,
          data : resp?.data
        },
      });
    } else {
      toast[resp?.success ?? 'error'](resp?.msg ?? 'Error');
    }
  };
  return (
    <>
      <Container fluid className='p-0 signIn__commonBg cmn__bgAligner'>
        <Row className='h-100 align-items-center'>
          <Col
            md={6}
            className=' d-flex justify-content-center align-items-center'
          >
            <img
              src={allImages.logo}
              className='img-fluid signIn__logo'
              alt=''
            />
          </Col>
          <Col md={6} className='d-flex justify-content-center'>
            <div className='p-3 p-sm-4 p-md-5 signIn__formHolder'>
              <div className='sign__formInps d-flex flex-column justify-content-center align-items-center gap-4'>
                <h5 className='text-center singIn__formTitle'>Sign-In</h5>
                <div>
                  <label className='cmn__inputLabel mb-2'>Email</label>
                  <div className='cmn__inputHolder py-1 px-2'>
                    <input
                      type='text'
                      placeholder='Enter Email'
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                      className='signIn__inputter signIn__widthInputter'
                    />
                  </div>
                  <p className='m-0 cmn__errorHint'>{Error?.email}</p>
                </div>
                <div>
                  <label className='cmn__inputLabel mb-2'>Password</label>
                  <div className='cmn__inputHolder py-1 px-2 position-relative'>
                    <input
                      type={changer ? 'text' : 'password'}
                      value={pass}
                      onChange={(e) => setPass(e.target.value)}
                      placeholder='Enter Password'
                      className='signIn__inputter signIn__widthInputter'
                    />
                    <FaEye
                      className='signIn__eye pointer'
                      onClick={() => setChanger(!changer)}
                    />
                  </div>
                  <p className='m-0 cmn__errorHint'>{Error?.email}</p>
                </div>

                <NavLink state={{ forgetPass: true }} to='/OtpVerify'>
                  <p className='m-0 singIn__forgotPw'>Forgot Password</p>
                </NavLink>

                <button
                  className='primary__btn rounded-1 mt-5'
                  onClick={() => onSubmit()}
                >
                  Submit
                </button>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default SignIn;

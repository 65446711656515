import './App.css';
import './Assets/css/Style.css';
import { Navigate, Route, Routes } from 'react-router-dom';
import SignIn from './Screens/SignIn';
import OTPVerification from './Screens/OTPVerification';
import ResetPassword from './Screens/ResetPassword';
import AddStudent from './Screens/AddStudent';
import Dashboard from './Screens/Dashboard';
import Requests from './Screens/Requests';
import AddBanner from './Screens/AddBanner';
import AddPlacedStudents from './Screens/AddPlacedStudents';
import EditProfile from './Screens/EditProfile';
import { useSelector } from 'react-redux';
import { useEffect, useState } from 'react';

function App() {
  const userdata = useSelector((state) => state?.redux?.userdata);
  const [token, setToken] = useState(true);

  useEffect(() => {
    const getToken = localStorage.getItem('token');
    setToken(getToken ? true : false);
  }, [userdata]);
  console.log('token', token);
  const authenticate = (comp) => {
    return token ? comp : <Navigate to={'/'} replace />;
  };

  return (
    <>
      <Routes>
        <Route path='/' element={<SignIn />} />
        <Route path='/OtpVerify' element={<OTPVerification />} />
        <Route path='/resetPassword' element={<ResetPassword />} />
        <Route path='/addStudent' element={<AddStudent />} />
        <Route path='/dashboard' element={authenticate(<Dashboard />)} />
        <Route path='/requests' element={authenticate(<Requests />)} />
        <Route path='/addBanner' element={<AddBanner />} />
        <Route path='/addPlacedStudents' element={<AddPlacedStudents />} />
        <Route path='/editProfile' element={authenticate(<EditProfile />)} />
        <Route path='/viewProfile' element={authenticate(<EditProfile />)} />
      </Routes>
    </>
  );
}

export default App;

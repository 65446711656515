import React, { useState } from 'react';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import { changeOn } from '../config/common';

function ParentDetails(props) {
  const [phone, setPhone] = useState('');
  const { error, formData } = props;
  return (
    <>
      <h5 className='text-center singIn__formTitle mb-5'>Parents Details</h5>
      <div className='mb-3'>
        <label className='cmn__inputLabel mb-2'>Father's Name</label>
        <div className='cmn__inputHolder py-1 px-2'>
          <input
            type='text'
            placeholder={`Enter Father's Name of the student`}
            className='signIn__inputter '
            id='fatherName'
            value={formData?.fatherName}
            onChange={(e) => props?.onChangeFunc(e)}
          />
        </div>
        <p className='m-0 cmn__errorHint'>{error?.fatherName}</p>
      </div>
      <div className='mb-3'>
        <label className='cmn__inputLabel mb-2'>Father's Mobile Number</label>
        <div className='cmn__inputHolder studDtls__phoneInpHolder px-2'>
          <PhoneInput
            country={'in'}
            value={formData?.fatherNumber}
            id='fatherNumber'
            onChange={(e) => changeOn('fatherNumber', e, props?.onChangeFunc)}
            // onChange={(phone) => setPhone({ phone })}
          />
        </div>
        <p className='m-0 cmn__errorHint'>{error?.fatherNumber}</p>
      </div>
      <div className='mb-3'>
        <label className='cmn__inputLabel mb-2'>Mother's Name</label>
        <div className='cmn__inputHolder py-1 px-2'>
          <input
            type='text'
            value={formData?.motherName}
            placeholder={`Enter Mother's Name of the student`}
            className='signIn__inputter '
            id='motherName'
            onChange={(e) => props?.onChangeFunc(e)}
          />
        </div>
        <p className='m-0 cmn__errorHint'>{error?.motherName}</p>
      </div>
      <div className='mb-4'>
        <label className='cmn__inputLabel mb-2'>Mother's Mobile Number</label>
        <div className='cmn__inputHolder studDtls__phoneInpHolder px-2'>
          <PhoneInput
            country={'in'}
            value={formData?.motherNumber}
            id='motherNumber'
            onChange={(e) => changeOn('motherNumber', e, props?.onChangeFunc)}

            // onChange={(phone) => setPhone({ phone })}
          />
        </div>

        <p className='m-0 cmn__errorHint'>{error?.motherNumber}</p>
      </div>
      <div className='mb-3'>
        <label className='cmn__inputLabel mb-2'>Guardian Name</label>
        <div className='cmn__inputHolder py-1 px-2'>
          <input
            type='text'
            value={formData?.guardianName}
            placeholder={`Guardian Name`}
            className='signIn__inputter '
            id='guardianName'
            onChange={(e) => props?.onChangeFunc(e)}
          />
        </div>
        <p className='m-0 cmn__errorHint'>{error?.guardianName}</p>
      </div>
      <div className='mb-3'>
        <label className='cmn__inputLabel mb-2'>Guardian's Mobile Number</label>
        <div className='cmn__inputHolder studDtls__phoneInpHolder px-2'>
          <PhoneInput
            country={'in'}
            value={formData?.guardianNumber}
            id='guardianNumber'
            onChange={(e) => changeOn('guardianNumber', e, props?.onChangeFunc)}

            // onChange={(phone) => setPhone({ phone })}
          />
        </div>
        <p className='m-0 cmn__errorHint'>{error?.guardianNumber}</p>
      </div>
    </>
  );
}

export default ParentDetails;

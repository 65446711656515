import { React, useEffect, useState } from "react";
import { Col, Container, Row, Button } from "react-bootstrap";
import { allImages } from "../Images";
import { FaArrowLeft } from "react-icons/fa6";

// components
import StudentDetails from "../Components/StudentDetails";
import ParentDetails from "../Components/ParentDetails";
import ResedentialDetails from "../Components/ResedentialDetails";
import CourseDetails from "../Components/CourseDetails";
import Alert from "../Components/Alert";
import { isEmpty } from "../config/common";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { saveStudent } from "../axios/student.axios";
function AddStudent() {
  const navigate = useNavigate();
  const [page, setPage] = useState(0);
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    nationality: "",
    gender: "",
    DOB: "",
    IDno: "",
    mobileNumber: "",
    maritalStatus: "",
    fatherName: "",
    fatherNumber: "",
    motherName: "",
    motherNumber: "",
    guardianName: "",
    guardianNumber: "",
    permanentAddress: "",
    currentAddress: "",
    city: "",
    state: "",
    pincode: "",
    courseName: "",
    employmentStatus: "",
    degree: "",
    source: "",
    aadharNum: "",
    bloodGroup: ""
  });
  const [error, setError] = useState({});

  const onChangeFunc = (e) => {
    const { id, value } = e.target;
    setError({});
    setFormData({ ...formData, [id]: value });
  };
  console.log("formmamdadma", formData);

  const onNextPageClick = () => {
    const err = {};
    if (page === 0) {
      if (!formData?.name) err.name = "Please enter name";
      if (!formData?.email) err.email = "Please enter email address";
      if (!formData?.DOB) err.DOB = "Please enter DOB";
      if (!formData?.mobileNumber)
        err.mobileNumber = "Please enter mobile number";
      if (!formData?.nationality) err.nationality = "Please enter nationality";
      if (!formData?.gender) err.gender = "Please enter gender";
      if (!formData?.bloodGroup) err.bloodGroup = "Please enter Blood Group";
      if (!formData?.IDno) err.IDno = "Please enter ID number";
      if (!formData?.aadharNum) err.aadharNum = "Please enter Aathar number";
      if (!formData?.maritalStatus)
        err.maritalStatus = "Please enter marital Status";
    } else if (page === 1) {
      if (!formData?.fatherName) err.fatherName = "Please enter fatherName";
      if (!formData?.fatherNumber)
        err.fatherNumber = "Please enter fatherNumber";
      if (!formData?.motherName) err.motherName = "Please enter motherName";
      if (!formData?.motherNumber)
        err.motherNumber = "Please enter motherNumber";
      if (!formData?.guardianName)
        err.guardianName = "Please enter guardianName";
      if (!formData?.guardianNumber)
        err.guardianNumber = "Please enter guardianNumber";
    } else if (page === 2) {
      if (!formData?.permanentAddress)
        err.permanentAddress = "Please enter permanentAddress";
      if (!formData?.currentAddress)
        err.currentAddress = "Please enter currentAddress";
      if (!formData?.city) err.city = "Please enter city";
      if (!formData?.state) err.state = "Please enter state";
      if (!formData?.pincode) err.pincode = "Please enter Pincode";
    } else if (page === 3) {
      if (!formData?.courseName) err.courseName = "Please enter courseName";
      if (!formData?.employmentStatus)
        err.employmentStatus = "Please enter employmentStatus";
      if (!formData?.degree) err.degree = "Please enter degree";
      if (!formData?.source) err.source = "Please enter source";
    }
    if (!isEmpty(err)) {
      setError(err);
      toast.error("Please fill the fields correctly");
      return err;
    } else setPage(page + 1);
  };

  const onSubmit = async () => {
    const valid = {};
    if (!formData?.courseName) valid.courseName = "Please enter courseName";
    if (!formData?.employmentStatus) valid.employmentStatus = "Please enter employmentStatus";
    if (!formData?.degree) valid.degree = "Please enter degree";
    if (!formData?.source) valid.source = "Please enter source";
    if (!isEmpty(valid)) return setError(valid);

    const resp = await saveStudent(formData);

    if (resp) {
      toast[resp?.success ?? "error"](resp?.msg ?? "Error");
      if (resp?.status === true) {
        console.log("akdhaidhaodid");
        setPage(page + 1);
      }
    }
  };

  useEffect(() => {
    const handleBeforeUnload = (e) => {
      const conf = window.confirm("If you refresh your data will be lost...!");
      if(conf) window.location.reload();
      else {
        e.preventDefault();
        e.returnValue = '';
      }
    };

    window.addEventListener('beforeunload', handleBeforeUnload);

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, []);

  return (
    <Container
      fluid
      className="p-0 signIn__commonBg cmn__bgAligner position-relative"
    >
      <button
        className="addStdt__backBtn"
        disabled={page === 0}
        onClick={() => setPage(page - 1)}
      >
        <FaArrowLeft className="me-2" />
        Back
      </button>
      <Row className="h-100 d-flex justify-content-center align-items-center addStudents__mainRow ">
        <Col
          md={page === 0 ? 4 : 6}
          className="d-flex justify-content-center align-items-center"
        >
          <img src={allImages.logo} className="img-fluid signIn__logo" alt="" />
        </Col>

        <Col
          md={page === "0" ? 8 : page === "1" ? 6 : 6}
          className="d-flex flex-column align-items-center gap-4 justify-content-center"
        >
          <div className="p-3 p-sm-4 p-md-5 signIn__formHolder">
            {page === 0 && (
              <StudentDetails
                error={error}
                setError={setError}
                onChangeFunc={onChangeFunc}
                formData={formData}
              />
            )}
            {page === 1 && (
              <ParentDetails
                formData={formData}
                error={error}
                setError={setError}
                onChangeFunc={onChangeFunc}
              />
            )}
            {page === 2 && (
              <ResedentialDetails
                formData={formData}
                error={error}
                setError={setError}
                onChangeFunc={onChangeFunc}
              />
            )}
            {page === 3 && (
              <CourseDetails
                formData={formData}
                error={error}
                setError={setError}
                onChangeFunc={onChangeFunc}
              />
            )}
            {page === 4 && <Alert formData={formData} />}

            <div
              className={`studDtls__bottomBtns d-flex  align-items-center gap-2 mt-5 ${
                page === 4 ? "justify-content-center" : "justify-content-end"
              }`}
            >
              {page !== 4 ? (
                <>
                  <button
                    className={`${
                      page === 0 || page === 4
                        ? "d-none"
                        : "otpVerify__plainBluebutton rounded-1"
                    }`}
                    onClick={() => setPage(page - 1)}
                  >
                    Previous
                  </button>

                  <buton
                    className="primary__btn rounded-1 pointer"
                    onClick={() => {
                      if (page === 3) onSubmit();
                      else onNextPageClick();
                    }}
                  >
                    {page === 3 ? "Add Student" : page === 4 ? "Done" : "Next"}
                  </buton>
                </>
              ) : (
                <buton
                  className="primary__btn rounded-1 pointer"
                  onClick={() => navigate("/dashboard")}
                >
                  Done
                </buton>
              )}
            </div>
          </div>
          {page === 4 ? (
            <></>
          ) : (
            <div className="addStdt__stepper d-flex justify-conetnt-center align-items-center gap-2">
              <button
                className={`addStdt__singleStep rounded-2 ${
                  page === 0 && "active"
                }`}
              ></button>
              <button
                className={`addStdt__singleStep rounded-2 ${
                  page === 1 && "active"
                }`}
              ></button>
              <button
                className={`addStdt__singleStep rounded-2 ${
                  page === 2 && "active"
                }`}
              ></button>
              <button
                className={`addStdt__singleStep rounded-2 ${
                  page === 3 && "active"
                }`}
              ></button>
            </div>
          )}
        </Col>
      </Row>
    </Container>
  );
}

export default AddStudent;

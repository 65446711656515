import React, { Component, Fragment, useEffect, useState } from 'react';
import Layout from '../Layout/Layout';
import { Dropdown } from 'react-bootstrap';
import ReactDatatable from '@ashvin27/react-datatable';
import { allData } from '../Data';
import { useNavigate } from 'react-router-dom';
import { getStudents, saveMultiStudents } from '../axios/student.axios';
import * as XLSX from 'xlsx';
import { toast } from 'react-toastify';
import { getPlacedStudents } from '../axios/admin.axios';
import AddStudentWrokModal from '../Modals/AddStudentWrokModal';

const Dashboard = () => {
  // post work state

  const [postWork, setPostWork] = useState(false);
  const handleShowPostWork = () => setPostWork(true);
  const handleClosePostWork = () => setPostWork(false);

  const navigate = useNavigate();
  // console.log(state.records[0].status.props,"asdfasfasdfasdf");
  const [students, setStudents] = useState([]);
  const [data, setData] = useState([]);
  const [filterData, setFilterData] = useState([]);
  const [search, setSearch] = useState('');

  const columns = [
    {
      key: 'id',
      text: 'Id No',
      className: 'id',
      align: 'center',
      sortable: true,
      cell: (rec) => {
        const getDate = new Date(
          new Date(rec?.createdAt).setDate(
            new Date(rec?.createdAt).getDate() + 2
          )
        ).setHours(0, 0, 0, 0);
        console.log(
          'gagagagaggaag',
          getDate,
          Date.now(),
          new Date(getDate).toISOString(),
          getDate > Date.now()
        );
        return (
          <div className='d-flex justify-content-center align-items-center gap-2'>
            {getDate > Date.now() ? (
              <button className='dash__newBatch rounded-1'>New</button>
            ) : (
              <></>
            )}
            <p className='exchange_table_values m-0'>
              {new Date(rec?.createdAt).toLocaleString()}
            </p>
          </div>
        );
      },
      // cell: (record, index) => (
      //   <div className='d-flex justify-content-center align-items-center gap-2'>
      //     <button className='dash__newBatch rounded-1'>New</button>
      //     <p className='exchange_table_values m-0'>IMD-101-07-121</p>
      //   </div>
      // ),
    },
    {
      key: 'name',
      text: 'Name',
      className: 'name',
      align: 'center',
      sortable: true,
      cell: (record, index) => (
        <p className='exchange_table_values m-0 text-center'>{record?.name}</p>
      ),
    },
    {
      key: 'course',
      text: 'Course',
      className: 'course',
      align: 'center',
      sortable: false,
      cell: (record, index) => (
        <p className='exchange_table_values m-0 text-center'>
          {record?.courseName}
        </p>
      ),
    },
    {
      key: 'location',
      text: 'Location',
      className: 'location',
      align: 'center',
      sortable: false,
      cell: (record, index) => (
        <p className='exchange_table_values m-0 exchange_table_address text-center'>
          {record?.city}
        </p>
      ),
    },
    {
      key: 'mobile',
      text: 'Mobile No',
      className: 'mobile',
      align: 'center',
      sortable: false,
      cell: (record, index) => (
        <p className='exchange_table_values m-0 text-center'>
          +{record?.mobileNumber}
        </p>
      ),
    },
    {
      key: 'gender',
      text: 'Gender',
      className: 'gender',
      align: 'center',
      sortable: false,
      cell: (record, index) => (
        <p className='exchange_table_values m-0 text-center'>
          {record?.gender}
        </p>
      ),
    },
    {
      key: 'action',
      text: '',
      className: 'action',
      align: 'center',
      sortable: false,
      cell: (record, index) => (
        <Dropdown className='dash__tableDrop'>
          <Dropdown.Toggle id='dropdown-basic'>
            <i class='fa-solid fa-ellipsis-vertical'></i>
          </Dropdown.Toggle>

          <Dropdown.Menu className='dash__tableDropMenu'>
            <Dropdown.Item
              className='dash__tableDropItem'
              onClick={() => navigate('/viewProfile', { state: record })}
            >
              <i class='fa-solid fa-user me-2' /> View Profile
            </Dropdown.Item>
            <Dropdown.Item
              className='dash__tableDropItem'
              onClick={() => navigate('/editProfile', { state: record })}
            >
              <i class='fa-solid fa-pen me-2' />
              Edit Profile
            </Dropdown.Item>
            <Dropdown.Item className='dash__tableDropItem'>
              <i class='fa-solid fa-book me-2' />
              DESC Record
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      ),
      // cell: (rec) =>  <div className="text-center"><button className={`rounded-4 ${rec.status.props.children == 'Completed' ? 'exchange_completed' : rec.status.props.children == 'Pending' ? 'exchange_pending' : rec.status.props.children == 'Failed' ? 'exchange_fail' : ''}`} >{rec.status.props.children}</button>
      // {console.log(rec.status.props.children,'recrecrec')}
      // </div>,
    },
  ];
  const config = {
    page_size: 10,
    length_menu: [10, 20, 50],
    filename: 'StudentDetails',
    no_data_text: 'No Student Details found!',
    language: {
      length_menu: 'Show _MENU_ result per page',
      filter: 'Search Student',
      info: 'Showing _START_ to _END_ of _TOTAL_ records',
      pagination: {
        first: <i class='fa-solid fa-angles-left' />,
        previous: <i class='fa-solid fa-angle-left' />,
        next: <i class='fa-solid fa-angle-right' />,
        last: <i class='fa-solid fa-angle-right' />,
      },
    },
    button: {
      excel: false,
      print: false,
    },
    show_length_menu: false,
    show_filter: false,
    show_pagination: true,
    show_info: true,
  };

  const extraButtons = [
    {
      className: 'btn btn-primary buttons-pdf',
      title: 'Export TEst',
      children: [
        <span>
          <i
            className='glyphicon glyphicon-print fa fa-print'
            aria-hidden='true'
          ></i>
        </span>,
      ],
      onClick: (event) => {
        console.log(event);
      },
    },
    {
      className: 'btn btn-primary buttons-pdf',
      title: 'Export TEst',
      children: [
        <span>
          <i
            className='glyphicon glyphicon-print fa fa-print'
            aria-hidden='true'
          ></i>
        </span>,
      ],
      onClick: (event) => {
        console.log(event);
      },
      onDoubleClick: (event) => {
        console.log('doubleClick');
      },
    },
  ];

  useEffect(() => {
    getDatas();
  }, []);

  useEffect(() => {
    if (search !== '') {
      const filtered = students.filter((item) =>
        Object.values(item).some(
          (val) =>
            val && val.toString().toLowerCase().includes(search.toLowerCase())
        )
      );
      setFilterData(filtered);
    } else {
      setFilterData(students);
    }
  }, [search, students]);

  const getDatas = async () => {
    const resp = await getStudents();
    console.log('resprespresp', resp);

    setStudents(resp?.data || students || []);
  };
  console.log('studentsdata', data);

  // const handleFileUpload = (event) => {
  //   const file = event.target.files[0];
  //   const reader = new FileReader();

  //   reader.onload = (e) => {
  //     const binaryStr = e.target.result;
  //     const workbook = XLSX.read(binaryStr, { type: 'binary' });

  //     const sheetName = workbook.SheetNames[0];
  //     const sheet = workbook.Sheets[sheetName];
  //     const sheetData = XLSX.utils.sheet_to_json(sheet);

  //     setData(sheetData);
  //     const getValArr = [];
  //     sheetData.map((val)=>{
  //       const err = Object.values(val).includes("");
  //       console.log("ererrerrr",err,Object.keys(val).length);
  //     })
  //     // onFileUpload(sheetData);
  //   };

  //   reader.readAsBinaryString(file);
  // };

  const handleFileUpload = (event) => {
    const file = event.target.files[0];
    const reader = new FileReader();

    reader.onload = (e) => {
      const binaryStr = e.target.result;
      const workbook = XLSX.read(binaryStr, { type: 'binary' });

      const sheetName = workbook.SheetNames[0];
      const sheet = workbook.Sheets[sheetName];

      // Use defval option to ensure empty cells are represented as empty strings
      const sheetData = XLSX.utils.sheet_to_json(sheet, { defval: '' });

      setData(sheetData);
      var getD = sheetData.map((val) => {
        const err = Object.values(val).includes('');
        return `${err}`;
      });
      if (getD.includes('true')) return toast.warn('Some datas are missing...');
      onFileUpload(sheetData);
    };

    reader.readAsBinaryString(file);
  };

  const onFileUpload = async (data) => {
    const getConfirm = window.confirm(
      `Are you sure... You want save ${data?.length} students?`
    );
    if (getConfirm) {
      const resp = await saveMultiStudents(data);
      toast[resp?.success ?? 'error'](resp?.msg ?? 'Error');
      getDatas();
    }
  };
  console.log('filterData', filterData);

  return (
    <>
      <Layout>
        <div className='dash__filtersHlder d-flex justify-content-xl-end align-items-center gap-4'>
          <div className='dash__fileUploader rounded-1 position-relative  d-flex justify-content-end p-1'>
            <input
              type='file'
              className='dash__fileInp'
              onChange={handleFileUpload}
            />
            <button className='dash__fileBtn rounded-1'>Choose File</button>
          </div>
          <button
            className='primary__btn rounded-1'
            onClick={() => navigate('/addStudent')}
          >
            <i class='fa-solid fa-plus me-2' /> Add Students
          </button>

          {/* <a href='/sampleData.xlsx' download='SampleData.xlsx'>
            <button className='primary__btn rounded-1'>
              <i class='fa-solid fa-plus me-2' /> Download sample file
            </button>
          </a> */}

          <button
            className='primary__btn rounded-1'
            onClick={() => handleShowPostWork()}
          >
            <i class='fa-solid fa-plus me-2' /> Post Work
          </button>

          <Dropdown className='dash__filterDrop'>
            <Dropdown.Toggle id='dropdown-basic'>
              <i class='fa-solid fa-filter' /> Filter
            </Dropdown.Toggle>

            <Dropdown.Menu>
              <Dropdown.Item href='#/action-1'>Action</Dropdown.Item>
              <Dropdown.Item href='#/action-2'>Another action</Dropdown.Item>
              <Dropdown.Item href='#/action-3'>Something else</Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>

          <div className='dash__searcherHolder rounded-1 d-flex justify-content start align-items-center gap-2 ps-2'>
            <input
              type='text'
              placeholder='Search Student'
              className='dash__searchFilter flex-grow-1'
              onChange={(e) => setSearch(e.target.value)}
            />
            <button className='dash__searchBtn'>
              <i class='fa-solid fa-magnifying-glass' />
            </button>
          </div>
        </div>

        <div className='dash__tableHolder mt-3'>
          <ReactDatatable
            config={config}
            // records={allData.dashTableData}
            records={filterData}
            columns={columns}
            // extraButtons={extraButtons}
          />
        </div>
      </Layout>

      {/* modal */}
      <AddStudentWrokModal show={postWork} handleClose={handleClosePostWork} />
      {/* end of modal */}
    </>
  );
};

export default Dashboard;

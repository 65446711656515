import React, { useEffect, useRef, useState } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { allImages } from '../Images';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import RangeSlider from 'react-range-slider-input';
import 'react-range-slider-input/dist/style.css';
import { useLocation, useNavigate } from 'react-router-dom';
import { changeOn, isEmpty } from '../config/common';
import { editStudent } from '../axios/student.axios';
import { toast } from 'react-toastify';

const EditProfile = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const path = location.pathname.split('/')[1];
  const studentData = location.state;
  console.log('location', location);

  const [formData, setFormData] = useState({});
  const [Errors, setError] = useState({});

  // offset settings

  const scrollRef = useRef();
  const scrollRefTwo = useRef();
  const scrollRefThree = useRef();
  const scrollRefFour = useRef();
  const scrollRefFive = useRef();
  const [offsetTop, setOffsetTop] = useState(0);

  useEffect(() => {
    // Function to update the offsetTop value
    const updateOffsetTop = () => {
      if (scrollRef.current.offsetTop == 0) {
        setOffsetTop(1);
      } else if (scrollRefTwo.current.offsetTop == 0) setOffsetTop(2);
      else if (scrollRefThree.current.offsetTop === 0) setOffsetTop(3);
    };

    updateOffsetTop();

    window.addEventListener('resize', updateOffsetTop);
    window.addEventListener('scroll', updateOffsetTop);
    return () => {
      window.removeEventListener('resize', updateOffsetTop);
      window.removeEventListener('scroll', updateOffsetTop);
    };
  }, [offsetTop]);

  console.log(offsetTop, 'offdffdf');

  useEffect(() => {
    if (studentData) setFormData(studentData);
  }, [studentData]);

  const onChangeFunc = (e) => {
    const { id, value } = e.target;
    setError({});
    setFormData({ ...formData, [id]: value });
  };

  const validation = () => {
    const err = {};
    if (!formData?.name) err.name = 'Please enter name';
    if (!formData?.email) err.email = 'Please enter email address';
    if (!formData?.DOB) err.DOB = 'Please enter DOB';
    if (!formData?.mobileNumber)
      err.mobileNumber = 'Please enter mobile number';
    if (!formData?.nationality) err.nationality = 'Please enter nationality';
    if (!formData?.gender) err.gender = 'Please enter gender';
    if (!formData?.bloodGroup) err.bloodGroup = 'Please enter Blood Group';
    if (!formData?.IDno) err.IDno = 'Please enter ID number';
    if (!formData?.aadharNum) err.aadharNum = 'Please enter Aathar number';
    if (!formData?.maritalStatus)
      err.maritalStatus = 'Please enter marital Status';
    if (!formData?.fatherName) err.fatherName = 'Please enter fatherName';
    if (!formData?.fatherNumber) err.fatherNumber = 'Please enter fatherNumber';
    if (!formData?.motherName) err.motherName = 'Please enter motherName';
    if (!formData?.motherNumber) err.motherNumber = 'Please enter motherNumber';
    if (!formData?.guardianName) err.guardianName = 'Please enter guardianName';
    if (!formData?.guardianNumber)
      err.guardianNumber = 'Please enter guardianNumber';
    if (!formData?.permanentAddress)
      err.permanentAddress = 'Please enter permanentAddress';
    if (!formData?.currentAddress)
      err.currentAddress = 'Please enter currentAddress';
    if (!formData?.city) err.city = 'Please enter city';
    if (!formData?.state) err.state = 'Please enter state';
    if (!formData?.pincode) err.pincode = 'Please enter Pincode';
    if (!formData?.courseName) err.courseName = 'Please enter courseName';
    if (!formData?.employmentStatus)
      err.employmentStatus = 'Please enter employmentStatus';
    if (!formData?.degree) err.degree = 'Please enter degree';
    if (!formData?.source) err.source = 'Please enter source';

    return err;
  };

  const onSubmit = async () => {
    const validate = validation();
    if (!isEmpty(validate))
      return setError(validate), toast.error('Please fix errors');

    const resp = await editStudent(formData);
    toast[resp?.success ?? 'error'](resp?.msg ?? 'Error');
    if (resp?.status === true) navigate(-1);
  };

  return (
    <>
      <Container
        fluid
        className='p-0 signIn__commonBg cmn__bgAligner position-relative'
      >
        <Row className='h-100 d-flex justify-content-center align-items-center addStudents__mainRow '>
          <Col
            md={4}
            className='d-flex justify-content-center align-items-center'
          >
            <img
              src={allImages.logo}
              className='img-fluid signIn__logo'
              alt=''
            />
          </Col>

          <Col
            md={7}
            className='d-flex flex-column align-items-center gap-4 justify-content-center'
          >
            <div className='editProfile__formScroller p-3 p-sm-4 p-md-5'>
              <Row>
                <Col lg={1}>
                  <div className='editProfile__stepper d-flex flex-column justify-conent-start gap-5 align-items-center '>
                    <div className='stepper__bgBorder'></div>
                    <button className='editProfile__singleStep rounded-5'>
                      1
                    </button>
                    <button className='editProfile__singleStep rounded-5'>
                      2
                    </button>
                    <button className='editProfile__singleStep rounded-5'>
                      3
                    </button>
                    <button className='editProfile__singleStep rounded-5'>
                      4
                    </button>
                    <button className='editProfile__singleStep rounded-5'>
                      5
                    </button>
                  </div>
                </Col>
                <Col lg={11}>
                  <div className='editProfile__singleForm' ref={scrollRef}>
                    <h5 className='text-center singIn__formTitle mb-5'>
                      Student Details
                    </h5>
                    <Row className='h-100 align-items-center'>
                      <Col lg={6}>
                        <div className=''>
                          <div className='cmn__inputHolder py-1 px-2 mb-4'>
                            <input
                              type='text'
                              placeholder='Name'
                              className='signIn__inputter '
                              id='name'
                              value={formData?.name}
                              disabled={path === 'viewProfile'}
                              onChange={(e) => onChangeFunc(e)}
                            />
                          </div>
                          <p className='m-0 cmn__errorHint'>{Errors?.name}</p>

                          <div className='cmn__inputHolder py-1 px-2 mb-4'>
                            <input
                              type='email'
                              placeholder='Email'
                              className='signIn__inputter '
                              id='email'
                              value={formData?.email}
                              disabled={path === 'viewProfile'}
                              onChange={(e) => onChangeFunc(e)}
                            />
                          </div>
                          <p className='m-0 cmn__errorHint'>{Errors?.email}</p>

                          <div className='cmn__inputHolder py-1 px-2 mb-4 pointer'>
                            {!formData?.DOB ? (
                              <p className='m-0 studDtls__placeholder'>
                                Date of Birth
                              </p>
                            ) : (
                              <input
                                className='signIn__inputter signIn__dateInput'
                                type='date'
                                value={formData?.DOB}
                                placeholder='Date of Birth'
                                id='DOB'
                                disabled={path === 'viewProfile'}
                                onChange={(e) => onChangeFunc(e)}
                              />
                            )}
                          </div>
                          <p className='m-0 cmn__errorHint'>{Errors?.DOB}</p>

                          <div className='cmn__inputHolder studDtls__phoneInpHolder px-2 mb-4'>
                            <PhoneInput
                              country={'in'}
                              // onChange={(phone) => setPhone({ phone })}
                              id='name'
                              value={formData?.mobileNumber}
                              disabled={path === 'viewProfile'}
                              onChange={(e) =>
                                changeOn('mobileNumber', e, onChangeFunc)
                              }
                            />
                          </div>
                          <p className='m-0 cmn__errorHint'>
                            {Errors?.mobileNumber}
                          </p>

                          <div className='cmn__inputHolder py-1 px-2 mb-4'>
                            <input
                              type='text'
                              placeholder='Blood Group'
                              className='signIn__inputter'
                              id='bloodGroup'
                              value={formData?.bloodGroup}
                              disabled={path === 'viewProfile'}
                              onChange={(e) => onChangeFunc(e)}
                            />
                          </div>
                          <p className='m-0 cmn__errorHint'>
                            {Errors?.bloodGroup}
                          </p>
                        </div>
                      </Col>
                      <Col lg={6}>
                        <div className=''>
                          <div className='cmn__inputHolder py-1 px-2 mb-4'>
                            <input
                              type='text'
                              placeholder='Aadhar Number or Alternate ID'
                              className='signIn__inputter '
                              id='aadharNum'
                              value={formData?.aadharNum}
                              disabled={path === 'viewProfile'}
                              onChange={(e) => onChangeFunc(e)}
                            />
                          </div>
                          <p className='m-0 cmn__errorHint'>
                            {Errors?.aadharNum}
                          </p>

                          <div className='cmn__inputHolder py-1 px-2 mb-4'>
                            <input
                              type='text'
                              placeholder='ID No'
                              className='signIn__inputter '
                              id='IDno'
                              value={formData?.IDno}
                              disabled={path === 'viewProfile'}
                              onChange={(e) => onChangeFunc(e)}
                            />
                          </div>
                          <p className='m-0 cmn__errorHint'>{Errors?.IDno}</p>

                          <div className='cmn__inputHolder py-1 px-2 mb-4'>
                            <input
                              type='text'
                              placeholder='Nationality'
                              className='signIn__inputter '
                              id='nationality'
                              value={formData?.nationality}
                              disabled={path === 'viewProfile'}
                              onChange={(e) => onChangeFunc(e)}
                            />
                          </div>
                          <p className='m-0 cmn__errorHint'>
                            {Errors?.nationality}
                          </p>

                          <div className='cmn__inputHolder py-1 px-2 mb-4'>
                            <input
                              type='text'
                              placeholder='Marital Status'
                              className='signIn__inputter'
                              id='maritalStatus'
                              value={formData?.maritalStatus}
                              disabled={path === 'viewProfile'}
                              onChange={(e) => onChangeFunc(e)}
                            />
                          </div>
                          <p className='m-0 cmn__errorHint'>
                            {Errors?.maritalStatus}
                          </p>

                          <div className='cmn__inputHolder py-1 px-2 mb-4'>
                            <input
                              type='text'
                              placeholder='Gender'
                              className='signIn__inputter'
                              id='gender'
                              value={formData?.gender}
                              disabled={path === 'viewProfile'}
                              onChange={(e) => onChangeFunc(e)}
                            />
                          </div>
                          <p className='m-0 cmn__errorHint'>{Errors?.gender}</p>
                        </div>
                      </Col>
                    </Row>
                  </div>

                  <div className='editProfile__singleForm' ref={scrollRefTwo}>
                    <h5 className='text-center singIn__formTitle mb-5'>
                      Parent Details
                    </h5>
                    <Row className='h-100 align-items-center'>
                      <Col lg={6}>
                        <div className='cmn__inputHolder py-1 px-2 mb-4'>
                          <input
                            type='text'
                            placeholder='Father Name'
                            className='signIn__inputter'
                            id='fatherName'
                            value={formData?.fatherName}
                            disabled={path === 'viewProfile'}
                            onChange={(e) => onChangeFunc(e)}
                          />
                        </div>
                        <p className='m-0 cmn__errorHint'>
                          {Errors?.fatherName}
                        </p>
                      </Col>
                      <Col lg={6}>
                        <div className='cmn__inputHolder studDtls__phoneInpHolder px-2 mb-4'>
                          <PhoneInput
                            country={'in'}
                            // onChange={(phone) => setPhone({ phone })}
                            id='name'
                            value={formData?.fatherNumber}
                            disabled={path === 'viewProfile'}
                            onChange={(e) =>
                              changeOn('fatherNumber', e, onChangeFunc)
                            }
                          />
                        </div>
                        <p className='m-0 cmn__errorHint'>
                          {Errors?.fatherNumber}
                        </p>
                      </Col>

                      <Col lg={6}>
                        <div className='cmn__inputHolder py-1 px-2 mb-4'>
                          <input
                            type='text'
                            placeholder='Mother Name'
                            className='signIn__inputter'
                            id='motherName'
                            value={formData?.motherName}
                            disabled={path === 'viewProfile'}
                            onChange={(e) => onChangeFunc(e)}
                          />
                        </div>
                        <p className='m-0 cmn__errorHint'>
                          {Errors?.motherName}
                        </p>
                      </Col>
                      <Col lg={6}>
                        <div className='cmn__inputHolder studDtls__phoneInpHolder px-2 mb-4'>
                          <PhoneInput
                            country={'in'}
                            id='motherNumber'
                            value={formData?.motherNumber}
                            disabled={path === 'viewProfile'}
                            onChange={(e) =>
                              changeOn('motherNumber', e, onChangeFunc)
                            }
                            // onChange={(phone) => setPhone({ phone })}
                          />
                        </div>
                        <p className='m-0 cmn__errorHint'>
                          {Errors?.motherNumber}
                        </p>
                      </Col>

                      <Col lg={6}>
                        <div className='cmn__inputHolder py-1 px-2 mb-4'>
                          <input
                            type='text'
                            placeholder='Guardian Name'
                            className='signIn__inputter'
                            id='guardianName'
                            value={formData?.guardianName}
                            disabled={path === 'viewProfile'}
                            onChange={(e) => onChangeFunc(e)}
                          />
                        </div>
                        <p className='m-0 cmn__errorHint'>
                          {Errors?.guardianName}
                        </p>
                      </Col>
                      <Col lg={6}>
                        <div className='cmn__inputHolder studDtls__phoneInpHolder px-2 mb-4'>
                          <PhoneInput
                            country={'in'}
                            id='guardianNumber'
                            value={formData?.guardianNumber}
                            disabled={path === 'viewProfile'}
                            onChange={(e) =>
                              changeOn('guardianNumber', e, onChangeFunc)
                            }
                            // onChange={(phone) => setPhone({ phone })}
                          />
                        </div>
                        <p className='m-0 cmn__errorHint'>
                          {Errors?.guardianNumber}
                        </p>
                      </Col>
                    </Row>
                  </div>

                  <div className='editProfile__singleForm' ref={scrollRefThree}>
                    <h5 className='text-center singIn__formTitle mb-5'>
                      Residential Details
                    </h5>
                    <Row className='h-100 align-items-center'>
                      <Col lg={6}>
                        <textarea
                          rows='4'
                          className='editProfile__textarea w-100 mb-4'
                          placeholder='Permanent Address'
                          id='permanentAddress'
                          value={formData?.permanentAddress}
                          disabled={path === 'viewProfile'}
                          onChange={(e) => onChangeFunc(e)}
                        />
                        <p className='m-0 cmn__errorHint'>
                          {Errors?.permanentAddress}
                        </p>
                      </Col>

                      <Col lg={6}>
                        <textarea
                          rows='4'
                          className='editProfile__textarea w-100 mb-4'
                          placeholder='Current Address'
                          id='currentAddress'
                          value={formData?.currentAddress}
                          disabled={path === 'viewProfile'}
                          onChange={(e) => onChangeFunc(e)}
                        />
                        <p className='m-0 cmn__errorHint'>
                          {Errors?.currentAddress}
                        </p>
                      </Col>
                      <Col lg={6}>
                        <div className='cmn__inputHolder py-1 px-2 mb-4'>
                          <input
                            type='text'
                            placeholder='State'
                            className='signIn__inputter '
                            id='state'
                            value={formData?.state}
                            disabled={path === 'viewProfile'}
                            onChange={(e) => onChangeFunc(e)}
                          />
                        </div>
                        <p className='m-0 cmn__errorHint'>{Errors?.state}</p>
                      </Col>
                      <Col lg={6}>
                        <div className='cmn__inputHolder py-1 px-2 mb-4'>
                          <input
                            type='text'
                            placeholder='City'
                            className='signIn__inputter '
                            id='city'
                            value={formData?.city}
                            disabled={path === 'viewProfile'}
                            onChange={(e) => onChangeFunc(e)}
                          />
                        </div>
                        <p className='m-0 cmn__errorHint'>{Errors?.city}</p>
                      </Col>
                      <Col lg={6}>
                        <div className='cmn__inputHolder py-1 px-2 mb-4'>
                          <input
                            type='text'
                            placeholder='Pincode'
                            className='signIn__inputter '
                            id='pincode'
                            value={formData?.pincode}
                            disabled={path === 'viewProfile'}
                            onChange={(e) => onChangeFunc(e)}
                          />
                        </div>
                        <p className='m-0 cmn__errorHint'>{Errors?.pincode}</p>
                      </Col>
                    </Row>
                  </div>

                  <div className='editProfile__singleForm' ref={scrollRefFour}>
                    <h5 className='text-center singIn__formTitle mb-5'>
                      Residential Details
                    </h5>
                    <Row className='h-100 align-items-center'>
                      <Col lg={6}>
                        <div className='cmn__inputHolder py-1 px-2 mb-4'>
                          <input
                            type='text'
                            placeholder='Course Name'
                            className='signIn__inputter '
                            id='courseName'
                            value={formData?.courseName}
                            disabled={path === 'viewProfile'}
                            onChange={(e) => onChangeFunc(e)}
                          />
                        </div>
                        <p className='m-0 cmn__errorHint'>
                          {Errors?.courseName}
                        </p>
                      </Col>
                      <Col lg={6}>
                        <div className='cmn__inputHolder py-1 px-2 mb-4'>
                          <input
                            type='text'
                            placeholder='employmentStatus'
                            className='signIn__inputter '
                            id='employmentStatus'
                            value={formData?.employmentStatus}
                            disabled={path === 'viewProfile'}
                            onChange={(e) => onChangeFunc(e)}
                          />
                        </div>
                        <p className='m-0 cmn__errorHint'>
                          {Errors?.employmentStatus}
                        </p>
                      </Col>
                      <Col lg={6}>
                        <div className='cmn__inputHolder py-1 px-2 mb-4'>
                          <input
                            type='text'
                            placeholder='Education'
                            className='signIn__inputter '
                            id='degree'
                            value={formData?.degree}
                            disabled={path === 'viewProfile'}
                            onChange={(e) => onChangeFunc(e)}
                          />
                        </div>
                        <p className='m-0 cmn__errorHint'>{Errors?.degree}</p>
                      </Col>
                      <Col lg={6}>
                        <div className='cmn__inputHolder py-1 px-2 mb-4'>
                          <input
                            type='text'
                            placeholder='source'
                            className='signIn__inputter '
                            id='source'
                            value={formData?.source}
                            disabled={path === 'viewProfile'}
                            onChange={(e) => onChangeFunc(e)}
                          />
                        </div>
                        <p className='m-0 cmn__errorHint'>{Errors?.source}</p>
                      </Col>
                    </Row>
                  </div>

                  <div className='editProfile__singleForm' ref={scrollRefFive}>
                    <h5 className='text-center singIn__formTitle'>
                      Student DESC
                    </h5>
                    <p className='m-0 editProfile__descHint text-center'>
                      (Discipline, Exposure, Subject, Communication)
                    </p>
                    <Row className='h-100 align-items-center mt-5'>
                      <Col lg={6} className='mb-4'>
                        <div className='editProfile__singleSlider'>
                          <p className='m-0 editProfile__label'>Discipline</p>

                          <div className='mt-2 d-flex justify-content-between align-items-center gap-2'>
                            <RangeSlider
                              defaultValue={[0, 100]}
                              className='single__thumb flex-grow-1'
                            />
                            <div className='editProfile__slideValue'>
                              <p className='m-0 text-center'>
                                0 <span> % </span>
                              </p>
                            </div>
                          </div>
                        </div>
                      </Col>

                      <Col lg={6} className='mb-4'>
                        <div className='editProfile__singleSlider'>
                          <p className='m-0 editProfile__label'>Discipline</p>

                          <div className='mt-2 d-flex justify-content-between align-items-center gap-2'>
                            <RangeSlider
                              defaultValue={[0, 100]}
                              className='single__thumb flex-grow-1'
                            />
                            <div className='editProfile__slideValue'>
                              <p className='m-0 text-center'>
                                0 <span> % </span>
                              </p>
                            </div>
                          </div>
                        </div>
                      </Col>

                      <Col lg={6} className='mb-4'>
                        <div className='editProfile__singleSlider'>
                          <p className='m-0 editProfile__label'>Discipline</p>

                          <div className='mt-2 d-flex justify-content-between align-items-center gap-2'>
                            <RangeSlider
                              defaultValue={[0, 100]}
                              className='single__thumb flex-grow-1'
                            />
                            <div className='editProfile__slideValue'>
                              <p className='m-0 text-center'>
                                0 <span> % </span>
                              </p>
                            </div>
                          </div>
                        </div>
                      </Col>

                      <Col lg={6} className='mb-4'>
                        <div className='editProfile__singleSlider'>
                          <p className='m-0 editProfile__label'>Discipline</p>

                          <div className='mt-2 d-flex justify-content-between align-items-center gap-2'>
                            <RangeSlider
                              defaultValue={[0, 100]}
                              className='single__thumb flex-grow-1'
                            />
                            <div className='editProfile__slideValue'>
                              <p className='m-0 text-center'>
                                0 <span> % </span>
                              </p>
                            </div>
                          </div>
                        </div>
                      </Col>

                      <div className='mt-5 d-flex justify-content-end align-items-center gap-3'>
                        <button
                          className='otpVerify__plainbutton'
                          onClick={() => navigate(-1)}
                        >
                          Cancel
                        </button>
                        <button
                          className='primary__btn rounded-1'
                          disabled={path === 'viewProfile'}
                          onClick={() => onSubmit()}
                        >
                          Save
                        </button>
                      </div>
                    </Row>
                  </div>
                </Col>
              </Row>
            </div>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default EditProfile;

import React, { useState } from 'react';
import { allImages } from '../Images';
import { Offcanvas } from 'react-bootstrap';
import Sidebar from './Sidebar';

const Header = () => {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  return (
    <>
      <div className='d-flex justify-content-between align-items-center header py-2 px-4'>
        <div className='d-flex justify-content-strat align-items-center gap-2'>
          <button className='header__burger d-xl-none' onClick={handleShow}>
            <i class='fa-solid fa-bars' />
          </button>
          <img
            src={allImages.whiteLogo}
            className='img-fluid header__logo'
            alt=''
          />
        </div>
        <p className='m-0 header__label'>Welcome Admin</p>
      </div>

      {/* offcanvas */}
      <Offcanvas
        show={show}
        onHide={handleClose}
        className='header__canvas'
        backdrop='static'
      >
        <Offcanvas.Body>
          <div className='position-relative'>
            <button
              className='header__burger ms-2 p-2 pointer '
              onClick={handleClose}
            >
              <i class='fa-solid fa-xmark'></i>
            </button>
          </div>
          <Sidebar />
        </Offcanvas.Body>
      </Offcanvas>
      {/* end of offcanvas */}
    </>
  );
};

export default Header;

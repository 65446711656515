import React, { useEffect, useState } from 'react';
import { allImages } from '../Images';
import { deleteNews, editNews, getAllNews, saveNews } from '../axios/admin.axios';
import { toast } from 'react-toastify';
import { formatDistanceToNow } from 'date-fns';
import { isEmpty } from '../config/common';
const News = () => {

const [newsData,setNewsData]=useState([]);
const [message,setMessage]=useState("");
const [editCon,setEditCon]=useState({})

  useEffect(()=>{
    getNews()
  },[])

  const getNews =async()=>{
    const resp = await getAllNews();
    setNewsData(resp?.data || newsData)
  }
console.log("editCon",editCon);
  const onSaveNews = async()=>{
    if(!message) return toast.error("Please enter new news...!");
    const resp = isEmpty(editCon) ? await saveNews({message}) : await editNews({message,_id:editCon?._id});
   console.log("resp",resp);
    toast[resp?.success ?? "error"](resp?.msg ?? "Error")
    setMessage('')
    setEditCon({})
    getNews()
  }

  const onDeleteNews = async(id)=>{
    const resp =await deleteNews({_id:id});
    toast[resp?.success ?? "error"](resp?.msg ?? "Error")
    getNews()
  }

  return (
    <>
      <div className='news d-flex flex-column'>
        <div>
          <div className='new__titleBox p-2'>
            <p className='m-0 text-center news__title'>News</p>
          </div>

          <div className='news__typerHolder m-3 p-2'>
            <p className='m-0 news__label'>New News</p>
            <div className='d-flex justify-content-between align-items-end gap-4'>
              <textarea
                rows='3'
                className='news__textarea flex-grow-1'
                placeholder='Type Something....'
                value={message}
                onChange={(e)=>{
                  if(e.target.value === "") setEditCon({});
                  setMessage(e.target.value)
                }}
              />
              <img src={allImages.send} onClick={()=>onSaveNews()} className='img-fluid pointer' alt='' />
            </div>
          </div>
        </div>

        <div className='news__scroller flex-grow-1'>
          <ul>
            {newsData.length !== 0 && newsData.map((item) => (
              <li>
                <div className='d-flex justify-content-between align-items-center gap-3 py-2 mb-2'>
                  <div className='flex-grow-1'>
                    <p className='m-0 news__title'>{item.message}</p>
                    <p className='news__time m-0'>{formatDistanceToNow(new Date(item.updatedAt), { addSuffix: true })}</p>
                  </div>
                  <div className='d-flex justify-content-end align-items-center'>
                    <button className='news__optionBtns p-2' onClick={()=>{setEditCon(item);setMessage(item?.message)}} >
                      <i class='fa-solid fa-pen' />
                    </button>
                    <p className='m-0 news__divider'>|</p>
                    <button onClick={()=>onDeleteNews(item?._id)} className='news__optionBtns p-2'>
                      <i class='fa-solid fa-trash' />
                    </button>
                  </div>
                </div>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </>
  );
};

export default News;

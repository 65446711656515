import React from 'react';

function Alert(props) {
  return (
    <>
      <h4 className='m-0 alert__title text-center'>
        One Student Added <br />
        <b>Successfully</b>
      </h4>

      <p className='m-0 mt-4 alert__hint text-center'>
        Username and password will be <br /> sent through ******@mail.com
      </p>
    </>
  );
}

export default Alert;

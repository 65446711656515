import axios from "axios";
import config from '../config/config';



export const saveStudent = async (data)=>{
    console.log('data',data);
    try{
        const resp = await axios({
            'method':'POST',
            'url':`${config.UserAPI}/saveStudent`,
            'data':data
        })
        console.log("returned response",resp.data)
        return resp.data;
    }catch(e){
        console.log("error on axios",e);
    }
} 

export const getStudents = async (data)=>{
    console.log('data',data);
    try{
        const resp = await axios({
            'method':'GET',
            'url':`${config.UserAPI}/getAllStudents`,
        })
        console.log("returned response",resp.data)
        return resp.data;
    }catch(e){
        console.log("error on axios",e);
    }
} 

export const saveMultiStudents = async (data)=>{
    console.log('data',data);
    try{
        const resp = await axios({
            'method':'POST',
            'url':`${config.UserAPI}/saveMultiStudents`,
            "data":data
        })
        console.log("returned response",resp.data)
        return resp.data;
    }catch(e){
        console.log("error on axios",e);
    }
} 

export const editStudent = async (data)=>{
    console.log('data',data);
    try{
        const resp = await axios({
            'method':'POST',
            'url':`${config.UserAPI}/editStudent`,
            "data":data
        })
        console.log("returned response",resp.data)
        return resp.data;
    }catch(e){
        console.log("error on axios",e);
    }
} 

export const studentLogin = async (data)=>{
    console.log('data',data);
    try{
        const resp = await axios({
            'method':'POST',
            'url':`${config.UserAPI}/studentLogin`,
            "data":data
        })
        console.log("returned response",resp.data)
        return resp.data;
    }catch(e){
        console.log("error on axios",e);
    }
} 

export const changeStudentDetail = async (data)=>{
    console.log('data',data);
    try{
        const resp = await axios({
            'method':'POST',
            'url':`${config.UserAPI}/changeStudentDetail`,
            "data":data
        })
        console.log("returned response",resp.data)
        return resp.data;
    }catch(e){
        console.log("error on axios",e);
    }
} 
import React, { useEffect, useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import { Pagination, Navigation, Autoplay } from 'swiper/modules';
import { allImages } from '../Images';
import { allData } from '../Data';
import { NavLink } from 'react-router-dom';
import { getPlacedStudents } from '../axios/admin.axios';
import config from '../config/config';
const PlacedStudents = () => {
  const [plascedArr, setPlacedArr] = useState([]);
  useEffect(() => {
    getPlacedStu();
  }, []);

  const getPlacedStu = async () => {
    const resp = await getPlacedStudents({ length: 5 });
    setPlacedArr(resp?.data || plascedArr);
  };

  console.log('palpaplpal', plascedArr);

  return (
    <>
      <div className='placedStudents p-3'>
        <div className='placedStds__top d-flex justify-content-between align-items-center'>
          <p className='m-0 placedStds__title'>Placed Students</p>
          <NavLink to='/addPlacedStudents' className='cmn__navlink'>
            <button className='news__optionBtns placedStds__addBtn'>
              <i class='fa-solid fa-square-plus' />
            </button>
          </NavLink>
        </div>

        <div className='placedStds__swiperHolder mt-2'>
          <Swiper
            rewind={true}
            loop
            autoplay
            navigation={true}
            pagination={{
              dynamicBullets: true,
            }}
            modules={[Pagination, Autoplay, Navigation]}
            className='placedStds__swiper'
          >
            {plascedArr.length != 0 &&
              plascedArr?.map((item) => (
                <SwiperSlide>
                  <div className='placedStds__card'>
                    <img
                      src={`${config?.image_Url}/placedStudents/${item?.image}`}
                      className='img-fluid placedStds__img'
                      alt=''
                    />
                    <p className='m-0 placedStds__name text-center mt-2'>
                      {item?.studentName}
                    </p>
                    <p className='m-0 placedStds__role text-center'>
                      {item?.companyName}
                    </p>
                  </div>
                </SwiperSlide>
              ))}
          </Swiper>
        </div>
      </div>
    </>
  );
};

export default PlacedStudents;

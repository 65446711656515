import React, { useEffect, useState } from 'react';
import Layout from '../Layout/Layout';
import { Accordion, Col, Dropdown, Form, Row } from 'react-bootstrap';
import { allImages } from '../Images';
import DatePicker from 'react-date-picker';
import 'react-date-picker/dist/DatePicker.css';
import 'react-calendar/dist/Calendar.css';
import CalendarModal from '../Modals/CalendarModal';
import { getStudentChanges } from '../axios/admin.axios';
import config from '../config/config';
import Profile from '../Assets/Images/studentOne.png';
import { formatDate, getCatDetails } from '../config/common';
import { changeStudentDetail } from '../axios/student.axios';
import { toast } from 'react-toastify';

const Requests = () => {
  const [approver, setApprover] = useState(true);
  const [value, onChange] = useState(new Date());
  const [arrData, setArrData] = useState([]);
  const [selectedArr, setSelectedArr] = useState([]);
  const [date, setDate] = useState(7);

  // calendar modal states

  const [showCalendar, setShowCalendar] = useState(false);
  const handleShowCalendar = () => setShowCalendar(true);
  const handleCloseCalendar = () => setShowCalendar(false);

  useEffect(() => {
    getData();
  }, []);

  const getData = async (props) => {

    console.log("dateArr",props);
    const getData = await getStudentChanges({filter:props});
    console.log('getData', getData);
    setArrData(getData?.data || []);
  };

  const onCheckChange = (item, status) => {
    var fillArr;
    if (selectedArr.some((val) => val._id === item?._id)) {
      if (
        selectedArr.some(
          (val) => val._id === item?._id && val.status === status
        )
      ) {
        fillArr = selectedArr.filter((val) => val?._id !== item?._id);
      } else {
        fillArr = selectedArr.map((val) => {
          if (val?._id === item?._id) val.status = status;
          return val;
        });
      }
    } else {
      fillArr = [...selectedArr, { ...item, status }];
    }
    setSelectedArr([...fillArr]);
  };

  console.log('selectedArrselectedArr', selectedArr);

  const onSelectAll = (ind, status) => {
    console.log('ahahahah', ind, status, arrData?.[ind], arrData);
    const fillArr = arrData[ind]?.changes.map((val) => {
      val.status = status ? 'approve' : 'pending';
      return val;
    });
    console.log('fillArrfillArr', fillArr);
    setSelectedArr([...fillArr]);
  };

  const onUpload = async (i) => {
    const resp = await changeStudentDetail({
      changeArr: selectedArr.filter((val) => val.studentID === arrData[i]?._id),
      student_id: arrData[i]?._id,
    });
    toast[resp?.success || 'error'](resp?.msg || 'Error');
    if (resp?.status) getData();
  };

  return (
    <>
      <Layout>
        <div className='req__filter h-100'>
          <div className='d-flex justify-content-between align-items-start'>
            <p className='text-center req__hint'>Student Requests</p>

            <button
              className='req__filterBtn rounded-1 px-3 py-1'
              onClick={handleShowCalendar}
            >
              <i class='fa-solid fa-filter' /> Filter
            </button>
          </div>

          <div className='reqs__Holder h-100'>
            {arrData?.length != 0 ? (
              arrData?.map((val, i) => {
                const student = val?.student;
                const firstChange = val?.changes?.[0];

                return (
                  <div className='reqs__accordHolder'>
                    <Accordion flush className='req__accordion'>
                      <Accordion.Item eventKey={i}>
                        <Accordion.Header>
                          <div className='req__accordHeadFlex w-100'>
                            <Row className=' align-items-center'>
                              <Col lg={4}>
                                <div className='d-flex justify-content-start align-items-center gap-4'>
                                  <img
                                    src={
                                      student?.profile
                                        ? `${config.image_Url}/student/${student?.profile}`
                                        : Profile
                                    }
                                    className='img-fluid req__accordHeadImg rounded-5'
                                    alt='profile'
                                  />
                                  <p className='m-0 req__studentName'>
                                    {val?.student?.name}
                                  </p>
                                </div>
                              </Col>
                              <Col lg={6}>
                                <p className='m-0 req__accordHeadCont'>
                                  {firstChange?.key?.toUpperCase()} :{' '}
                                  {firstChange?.oldValue} to{' '}
                                  {firstChange?.newValue}
                                </p>
                              </Col>
                              <Col lg={2} className='align-self-end'>
                                <p className='m-0 req__accordHeadTime text-end'>
                                  {new Date(
                                    firstChange?.createdAt
                                  ).toLocaleString()}
                                </p>
                              </Col>
                            </Row>
                          </div>
                        </Accordion.Header>
                        <Accordion.Body>
                          <div className='req__accordBodyScroller pe-2 pb-3'>
                            {val?.changes?.map((item, ind) => {
                              return (
                                <Row className='align-items-center req__accordSingleReq pb-3 mt-3'>
                                  {ind === 0 && (
                                    <div className='d-flex justify-content-end align-items-center mb-3 gap-4'>
                                      <div className='req__slctAllLabel'>
                                        <Form className='req__selectAllCheck'>
                                          <div key={'checkbox'} className=''>
                                            <Form.Check
                                              type={'checkbox'}
                                              id={`check-api-${'checkbox'}`}
                                            >
                                              <Form.Check.Input
                                                type={'checkbox'}
                                                isValid
                                                onChange={(e) =>
                                                  onSelectAll(
                                                    i,
                                                    e.target.checked
                                                  )
                                                }
                                              />
                                              <Form.Check.Label>
                                                Select All
                                              </Form.Check.Label>
                                            </Form.Check>
                                          </div>
                                        </Form>
                                      </div>

                                      <div className='d-flex justify-content-center align-item-center gap-4'>
                                        <p className='m-0 req__approveLabel'>
                                          Approve
                                        </p>
                                        <p className='m-0 req__approveLabel'>
                                          {' '}
                                          Reject
                                        </p>
                                      </div>
                                    </div>
                                  )}

                                  <Col lg={8}>
                                    <h5 className='m-0 req_contDestination'>
                                      Destination :{' '}
                                      <span>
                                        {getCatDetails(item?.key)}{' '}
                                        <i class='fa-solid fa-angle-right' />{' '}
                                        {item?.key}
                                      </span>
                                    </h5>

                                    <p className='m-0 req__contChange mt-2'>
                                      Changes :{' '}
                                      <span>
                                        {item?.oldValue} to {item?.newValue}
                                      </span>
                                    </p>
                                  </Col>
                                  <Col lg={4}>
                                    <div className='req__checkSelection d-flex justify-content-end mr-3'>
                                      {/* <label class="switch">
                                      <input type="checkbox" checked={true} />
                                      <span class="slider"></span>
                                    </label> */}
                                      <div className='req__acceptChecker'>
                                        <Form className='req__selectAllCheck'>
                                          <div key={'checkbox'} className=''>
                                            <Form.Check
                                              type={'checkbox'}
                                              id={`check-api-${'checkbox'}`}
                                            >
                                              <Form.Check.Input
                                                type={'checkbox'}
                                                isValid
                                                checked={selectedArr?.some(
                                                  (val) =>
                                                    val._id === item?._id &&
                                                    val.status === 'approve'
                                                )}
                                                onClick={() =>
                                                  onCheckChange(item, 'approve')
                                                }
                                              />
                                            </Form.Check>
                                          </div>
                                        </Form>
                                      </div>
                                      <div className=' req__rejectChecker'>
                                        <Form className='req__selectAllCheck '>
                                          <div key={'checkbox'} className=''>
                                            <Form.Check
                                              type={'checkbox'}
                                              id={`check-api-${'checkbox'}`}
                                            >
                                              <Form.Check.Input
                                                type={'checkbox'}
                                                isValid
                                                checked={selectedArr?.some(
                                                  (val) =>
                                                    val._id === item?._id &&
                                                    val.status === 'reject'
                                                )}
                                                onClick={() =>
                                                  onCheckChange(item, 'reject')
                                                }
                                              />
                                            </Form.Check>
                                          </div>
                                        </Form>
                                      </div>
                                    </div>
                                  </Col>
                                </Row>
                              );
                            })}

                            <div className='req__accordBodyDualBtns d-flex justify-content-end align-items-center gap-3 mt-4'>
                              <button
                                className='primary__btn rounded-1'
                                // disabled={approver}
                                onClick={() => onUpload(i)}
                              >
                                Upload
                              </button>
                              {/* <button
                              className="primary__btn rounded-1"
                              disabled={approver}
                            >
                              Reject
                            </button> */}
                            </div>
                          </div>
                        </Accordion.Body>
                      </Accordion.Item>
                    </Accordion>
                  </div>
                );
              })
            ) : (
              <div className='req__empty h-100 d-flex flex-column justify-content-center align-items-center'>
                <img src={allImages.NoReq} className='img-fluid' alt='' />
                <p className='text-center req__hint'>No Requests Yet</p>
              </div>
            )}
          </div>
        </div>
      </Layout>

      {/* modals */}
      {showCalendar && <CalendarModal show={showCalendar} date={date} setDate={setDate}  getData={getData} handleClose={handleCloseCalendar} />}
      {/* end of modals */}
    </>
  );
};

export default Requests;

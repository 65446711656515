import React, { useState } from 'react';
import Select from 'react-select';
import { changeOn } from '../config/common';

function CourseDetails(props) {
  const [selectedOption, setSelectedOption] = useState(null);
  const options = [
    { value: 'tamilnadu', label: 'TamilNadu' },
    { value: 'kerala', label: 'Kerala' },
    { value: 'andhra', label: 'Andhra Pradesh' },
    { value: 'karnataka', label: 'Karnataka' },
  ];
  const styles = {
    option: (styles, { isFocused, isSelected, isHovered }) => ({
      ...styles,
      color: '#fff',
      background: isFocused
        ? '#0081F9'
        : isSelected
        ? '#74c69d'
        : isHovered
        ? '#0081F9'
        : '#fff',

      zIndex: 1,
      cursor: 'pointer',
      fontSize: '13px',
    }),

    option: (styles, { isFocused, isSelected, isHovered }) => {
      // const color = chroma(data.color);

      return {
        ...styles,
        backgroundColor: isHovered
          ? '#0081F9'
          : isSelected
          ? '#74c69d'
          : isFocused
          ? '#0081F9'
          : '#fff',
        cursor: 'pointer',
        color: isHovered
          ? '#fff'
          : isSelected
          ? '#000'
          : isFocused
          ? '#fff'
          : '#000',
        fontSize: '13px',
      };
    },
    valueContainer: (provided, { isFocused, isSelected, isHovered }) => ({
      ...provided,
      height: '33px',
      border: '1px solid #282828',
      backgroundColor: isHovered
        ? 'transperant'
        : isSelected
        ? 'transperant'
        : isFocused
        ? 'transperant'
        : 'transperant',
      // border: "1px solid rgba(34, 34, 34, 0.32)",
      // borderRadius: 5,
      fontSize: '13px',
      color: '#000',
    }),
    control: (provided, { isFocused, isSelected, isHovered }) => ({
      ...provided,
      height: '33px',
      border: '1px solid #00CABA',
      borderRadius: 5,
      backgroundColor: isHovered
        ? 'transperant'
        : isSelected
        ? 'transperant'
        : isFocused
        ? 'transperant'
        : 'transperant',
      // backgroundColor: "#fff",
      border: 'none',
      outline: 'none',
      boxShadow: 'none',
      color: '#000',
    }),
    indicatorSeparator: (provided, state) => ({
      ...provided,
      display: 'none',
    }),
    indicatorsContainer: (provided, state) => ({
      ...provided,
      height: '33px',
      position: 'absolute',
      right: 0,
      top: 0,
      color: '#6C6A81',
      // display: 'none',
    }),
    singleValue: (provided, state) => ({
      ...provided,
      color: '#000',
    }),
    menuList: (base) => ({
      ...base,
      // kill the white space on first and last option
      padding: 0,
    }),
  };
  const { error, formData } = props;
  return (
    <>
      <h5 className='text-center singIn__formTitle mb-5'>Course Details</h5>
      <div className='mb-3'>
        <label className='cmn__inputLabel mb-2'>Course Name</label>
        <div className='studDtls__selectHolder position-relative'>
          <Select
            placeholder='Select Course Name'
            defaultValue={selectedOption}
            // onChange={setSelectedOption}
            options={options}
            styles={styles}
            onChange={(e) =>
              changeOn('courseName', e.value, props?.onChangeFunc)
            }
            value={
              formData?.courseName
                ? { value: formData?.courseName, label: formData?.courseName }
                : null
            }
          />
        </div>
        <p className='m-0 cmn__errorHint'>{error?.courseName}</p>
      </div>
      <div className='mb-3'>
        <label className='cmn__inputLabel mb-2'>Employee Starus</label>
        <div className='studDtls__selectHolder position-relative'>
          <Select
            placeholder='Select Employment Status'
            defaultValue={selectedOption}
            // onChange={setSelectedOption}
            options={options}
            styles={styles}
            onChange={(e) =>
              changeOn('employmentStatus', e.value, props?.onChangeFunc)
            }
            value={
              formData?.employmentStatus
                ? {
                    value: formData?.employmentStatus,
                    label: formData?.employmentStatus,
                  }
                : null
            }
          />
        </div>
        <p className='m-0 cmn__errorHint'>{error?.employmentStatus}</p>
      </div>
      <div className='mb-3'>
        <label className='cmn__inputLabel mb-2'>Degree</label>
        <div className='studDtls__selectHolder position-relative'>
          <Select
            placeholder='Select Degree'
            defaultValue={selectedOption}
            value={
              formData?.degree
                ? { value: formData?.degree, label: formData?.degree }
                : null
            }
            // onChange={setSelectedOption}
            options={options}
            styles={styles}
            onChange={(e) => changeOn('degree', e.value, props?.onChangeFunc)}
          />
        </div>
        <p className='m-0 cmn__errorHint'>{error?.degree}</p>
      </div>
      <div className='mb-3'>
        <label className='cmn__inputLabel mb-2'>Source</label>
        <div className='studDtls__selectHolder position-relative'>
          <Select
            placeholder='Select Source'
            value={
              formData?.source
                ? { value: formData?.source, label: formData?.source }
                : null
            }
            // defaultValue={selectedOption}
            // onChange={setSelectedOption}
            options={options}
            styles={styles}
            onChange={(e) => changeOn('source', e.value, props?.onChangeFunc)}
          />
        </div>
        <p className='m-0 cmn__errorHint'>{error?.source}</p>
      </div>
    </>
  );
}

export default CourseDetails;

import React, { useState } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { allImages } from '../Images';
import { FaEye } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';
import { changeAdminPassword } from '../axios/admin.axios';
import { toast } from 'react-toastify';
import { isEmpty } from '../config/common';

const ResetPassword = () => {
  const [newPw, setNewPw] = useState(false);
  const [confirmPw, setConfirmPw] = useState(false);
  const navigate = useNavigate();
  const [newPass, setNewPass] = useState('');
  const [consfirmPass, setConfirmPass] = useState('');
  const [Error, setError] = useState({});

  const onSubmit = async () => {
    const err = {};
    if (!newPass) err.newPass = "New password can't be empy";
    if (!consfirmPass) err.consfirmPass = "Confirm password can't be empy";
    if (newPass && consfirmPass && newPass != consfirmPass)
      err.consfirmPass = 'new password and confirm password  should be  same';
    if (!isEmpty(err)) return setError(err);
    const resp = await changeAdminPassword({ password: newPass });
    toast[resp?.success ?? 'error'](resp?.msg);
    if (resp?.status) navigate('/');
  };

  return (
    <>
      <Container fluid className='p-0 signIn__commonBg cmn__bgAligner'>
        <Row className='h-100 align-items-center'>
          <Col
            md={6}
            className=' d-flex justify-content-center align-items-center'
          >
            <img
              src={allImages.logo}
              className='img-fluid signIn__logo'
              alt=''
            />
          </Col>
          <Col md={6} className='d-flex justify-content-center'>
            <div className='p-3 p-sm-4 p-md-5 text-center signIn__formHolder'>
              <h5 className='text-center singIn__formTitle'>Reset Password</h5>

              <div className='d-flex flex-column justify-content-center align-items-center gap-4 mt-5'>
                <div>
                  <div className='cmn__inputHolder py-1 px-2 position-relative'>
                    <input
                      type={newPw ? 'text' : 'password'}
                      placeholder='Password'
                      className='signIn__inputter signIn__widthInputter'
                      value={newPass}
                      onChange={(e) => setNewPass(e.target.value)}
                    />
                    <FaEye
                      className='signIn__eye pointer'
                      onClick={() => setNewPw(!newPw)}
                    />
                  </div>
                  <p className='m-0 cmn__errorHint text-start'>
                    {Error?.newPass}
                  </p>
                </div>
                <div>
                  <div className='cmn__inputHolder py-1 px-2 position-relative'>
                    <input
                      type={confirmPw ? 'text' : 'password'}
                      placeholder='Password'
                      className='signIn__inputter signIn__widthInputter'
                      value={consfirmPass}
                      onChange={(e) => setConfirmPass(e.target.value)}
                    />
                    <FaEye
                      className='signIn__eye pointer'
                      onClick={() => setConfirmPw(!confirmPw)}
                    />
                  </div>
                  <p className='m-0 cmn__errorHint text-start'>
                    {Error?.consfirmPass}
                  </p>
                </div>
                <button
                  className='primary__btn rounded-1 mt-4'
                  onClick={() => onSubmit()}
                >
                  Create Password
                </button>
                <button
                  className='otpVerify__plainbutton mt-3'
                  onClick={() => navigate('/signIn')}
                >
                  Cancel
                </button>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default ResetPassword;
